import * as THREE from 'three'
import v from './../../shaders/vertex.glsl'
import f from './../../shaders/fragment.glsl'
import CONFIG from '../constants/CONFIG'

export default class Sea {

    constructor(_scene) {
        // Geometry
        const segments = CONFIG.MAP_RADIUS * 4
        const geometry = new THREE.PlaneGeometry(
            CONFIG.MAP_RADIUS * 2,
            CONFIG.MAP_RADIUS * 2,
            segments,
            segments
        )
        //Material
        this.material = new THREE.ShaderMaterial({
            vertexShader: v,
            fragmentShader: f,
            uniforms: {
                uTime: { value: 0 }
            },
            transparent: true
        })
        this.startTime = Date.now()
        this.element = new THREE.Mesh(geometry, this.material)
        this.element.rotation.x = Math.PI / -2
        _scene.add(this.element)
    }

    update() {
        this.material.uniforms.uTime.value = Date.now() - this.startTime
    }

}