import EVENTS from "../constants/EVENTS";
import SingleBarge from "./SingleBarge";

export default class Barges {

    constructor(_scene, _gltfLoader) {
        this.id = null
        this.scene = _scene
        this.loader = _gltfLoader
        this.elements = {}
    }

    elementsPositions() {
        return Object.entries(this.elements).map( ([_id, _element]) => ({
            id: _id,
            x: _element.x,
            z: _element.z,
            rotY: _element.rotY
        }))
    }

    update() {
        Object.entries(this.elements).forEach(([_id, _data]) => {
            if (_data.needUpdate) {
                if (_data.barge) {
                    _data.barge.update(_data)
                } else {
                    _data.barge = new SingleBarge(this.scene, this.loader, _data)
                }
                _data.needUpdate = false
            }
        })
    }

    initializeBargeElement(_data) {
        this.elements[_data.id] = _data
        this.elements[_data.id].needUpdate = true
    }

    updateBargeStyle(_update) {
        if (!this.elements[_update.id] || !this.elements[_update.id].barge) { return }
        this.elements[_update.id].barge.updateBoatMaterial(_update.property, _update.value, true)
    }

    updateBargeElement(_data) {
        this.elements[_data.id].x = _data.x
        this.elements[_data.id].z = _data.z
        this.elements[_data.id].rotY = _data.rotY
        this.elements[_data.id].needUpdate = true
    }

    saveBargeElementData(_data) {
        if (!_data || !_data.id ) { return }
        if (_data.id == this.id) { return }
        this.elementAlreadyExists(_data.id) ? this.updateBargeElement(_data) : this.initializeBargeElement(_data)
    }

    removeBargeElement(_id) {
        if (this.elements[_id] && this.elements[_id].barge) {
            this.scene.remove(this.elements[_id].barge.element)
        }
        delete this.elements[_id]
    }

    elementAlreadyExists(_id) {
        return this.elements[_id] !== undefined
    }

    handleMultibargesEvent(_event, _data, _socketId) {
        this.id = _socketId
        switch (_event) {
            case EVENTS.ON.INIT:
                Object.entries(_data).forEach( ([_, _bargeData]) => this.saveBargeElementData(_bargeData))
                break
            case EVENTS.ON.BARGE_UPDATE:
                this.saveBargeElementData(_data)
                break
            case EVENTS.ON.BARGE_STYLE_UPDATE:
                this.updateBargeStyle(_data)
                break
            case EVENTS.ON.BARGE_DISCONNECTED:
                this.removeBargeElement(_data)
                break
            default: break
        }
    }

}