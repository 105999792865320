import WheelTouchScreenCompatibleManager from "../managers/WheelTouchScreenCompatibleManager"
import StatsGrid from "./components/StatsGrid"

const PLAYERS_CONFIG ={
    SCROLLY_LOCKED_LIMIT: 60,
    SCROLLX_MAXIMUM: 85,
    SPEED_FACTOR: 0.03
}

export default class RetrospectivePage {

    constructor() {
        this.alive = true
        this.playersSpeed = 0
        this.contentView = document.querySelector('#js-contentView')
        this.playersBox = this.contentView.querySelector('#js-players')
        this.playerCards = this.playersBox.querySelectorAll('.playerCard')
        this.funBubblesBoxes = this.contentView.querySelectorAll('.funBubblesBox')
        this.funBubblesRotation = 0
        this.onResize()
        this.initializeScrollState()
        this.statsGrid = new StatsGrid(this.contentView)
        this.onWheelCallback = e => this.onWheel(e)
        this.wheelTouchScreenCompatibleManager = new WheelTouchScreenCompatibleManager(this.contentView)
        this.contentView.addEventListener('wheelTouchScreenCompatible', this.onWheelCallback)
        this.onResizeCallback = _ => { this.onResize() }
        window.addEventListener('resize', this.onResizeCallback)
        requestAnimationFrame(_ => this.update())
    }

    update() {
        if (!this.alive) { return }
        requestAnimationFrame(_ => this.update())
        // Players
        this.playersBox.style.transform = `translateX(${-1 * this.playersScroll}%) translateY(-50%)`
        this.playersSpeed = Math.min(200, Math.max(-200, 0.8 * this.playersSpeed))
        this.playerCards.forEach(card => {
            card.style.transform = `rotate(${-0.08 * this.playersSpeed}deg)`
        })
        if (this.playersScroll > PLAYERS_CONFIG.SCROLLY_LOCKED_LIMIT) {
            if (this.playersScroll > PLAYERS_CONFIG.SCROLLX_MAXIMUM && this.playersSpeed > 0 && this.contentView.scrollTop == 0) {
                this.specialAutoScrollRunning = true
                const scrollStep = this.contentView.offsetHeight * 1.1 / 15
                for (let i = 0; i <= 15; i++) {
                    setTimeout(_ => {
                        this.contentView.scrollTop = scrollStep * i
                        if (i == 15) {
                            this.contentView.classList.add('running')
                            this.specialAutoScrollRunning = false
                        }
                    }, i * 1000 / 60)
                }
            } else {
                this.contentView.classList.add('running')
            }
        } else {
            this.contentView.classList.remove('running')
            return
        }
        // Projects
        this.projects.forEach(project => {
            const sideFactor = project.right ? -1 : 1
            project.image.style.transform = `translateY(${project.delta * 0.4}px) translateX(${project.delta * 0.05 * sideFactor}px)`
        })
        // Bubbles
        this.funBubblesBoxes.forEach( (box,index) => {
            box.style.transform = `rotate(${this.funBubblesRotation + index * 60}deg)`
        })
    }

    onWheel(_e) {
        const delta = Math.abs(_e.detail.deltaY) >= Math.abs(_e.detail.deltaX) ? _e.detail.deltaY : _e.detail.deltaX
        const scrollTop = this.contentView.scrollTop
        // Bubbles effect
        this.funBubblesRotation = scrollTop * 0.2
        // Players scroll effect
        if (!this.specialAutoScrollRunning) {
            this.playersScroll = Math.max(this.playersScroll + (delta * PLAYERS_CONFIG.SPEED_FACTOR), 0)
            if (this.playersScroll > 0) {
                this.playersSpeed += delta
            }
        }
        // Projects scroll effect
        const scrollMiddle = scrollTop + (this.windowHeight * 0.5)
        this.projects.forEach(project => {
            project.delta = project.middle - scrollMiddle
        })
    }

    onResize() {
        this.windowHeight = this.contentView.offsetHeight
        this.projects = [...this.contentView.querySelectorAll('.js-project')].map(elem => {
            return {
                middle: Math.round(elem.offsetTop + (elem.offsetHeight * 0.5)),
                image: elem.querySelector('.imgBackground'),
                right: elem.classList.contains('right'),
                delta: 0
            }
        })
    }

    initializeScrollState() {
        const scrollTop = this.contentView.scrollTop
        if (scrollTop <= 0) {
            this.playersScroll = 0
            return
        }
        this.playersScroll = PLAYERS_CONFIG.SCROLLY_LOCKED_LIMIT + (scrollTop * PLAYERS_CONFIG.SPEED_FACTOR)
        this.onWheel({ detail: { deltaY: 0, deltaX: 0} })
    }

    kill() {
        window.removeEventListener('resize', this.onResizeCallback)
        this.contentView.removeEventListener('wheelTouchScreenCompatible', this.onWheelCallback)
        this.wheelTouchScreenCompatibleManager.kill()
        this.statsGrid.kill()
        this.alive = false
    }

}