import StatsGrid from "./components/StatsGrid"

export default class NewMandatePage {

    constructor() {
        this.contentView = document.querySelector('#js-contentView')
        this.statsGrid = new StatsGrid(this.contentView)
        this.members = document.querySelectorAll(".member")
        this.membersPictures = document.querySelectorAll(".member .picture")
        this.membersBlock = document.querySelector(".memberBlock")
        this.familyMembersBlock = document.querySelector(".familyMember")
        this.memberElementHeight = 0
        this.startTop = 0
        this.positionMembers = []
        this.element = 0
        this.lastPosition = 0
        this.openCircle()
        this.setPositionElement()

        window.addEventListener('resize', () => {this.setPositionElement()})
        this.contentView.addEventListener('scroll', (delta) => { this.setMember(delta) })
    }

    setMember(delta = 0)
    {
        const diff =  this.contentView.scrollTop - this.lastPosition
        this.lastPosition = this.contentView.scrollTop
        const scrollY = this.contentView.scrollTop
        if (scrollY >= this.startTop) {
            if ( diff  >= 0 && this.element != (this.members.length-1)) {
                if (( this.positionMembers[this.element + 1] ) < (scrollY - this.startTop)) {
                    this.members[this.element].classList.add("passed")
                    this.element++
                    this.members[this.element].classList.add("active")
                }
            } else if (diff < 0 && this.element != 0) {
                if ((this.positionMembers[this.element - 1] + this.memberElementHeight) > ((scrollY - this.startTop))) {
                    this.members[this.element].classList.remove("active")
                    this.element--
                    this.members[this.element].classList.remove("passed")
                }
            }
            this.scrollingImageContinuousTransition(scrollY)
        }
    }

    scrollingImageContinuousTransition(_scrollY) {
        const delta = (_scrollY - this.startTop) - this.positionMembers[this.element]
        const ratio = delta / this.memberElementHeight
        const y = 120 - (ratio * 80)
        this.membersPictures[this.element].style.transform = `translateY(${y}px)`
    }

    openCircle()
    {
        const circles =document.querySelectorAll('.containerCircle .circle')
        circles.forEach( (elem) =>
        {
            if(elem.getBoundingClientRect().top >= 0 && elem.getBoundingClientRect().bottom <= window.innerHeight) {
                elem.classList.add('appearCircle')
            }
            else
            {
                elem.classList.remove('appearCircle')
            }
        })
    }

    setPositionElement()
    {
        this.members = document.querySelectorAll(".member")
        this.positionMembers = []
        this.members.forEach((elem,i) =>
        {
            this.positionMembers[i] = elem.offsetTop
        })
        if (this.members[0]) {
            this.memberElementHeight = this.members[0].clientHeight
        }
        this.startTop = this.membersBlock.offsetTop + this.familyMembersBlock.offsetTop
        this.setMember()
    }


    kill()
    {
        this.statsGrid.kill()
        this.contentView.removeEventListener("wheel", (e) =>{})
        window.removeEventListener("resize", (e) =>{})
    }
}