import ISLANDS from "../constants/ISLANDS"
import ACTIONS from "../constants/ACTIONS"
import ContentView from "../content/ContentView"
import CONFIG from "../constants/CONFIG"
import MathHelper from "./MathHelper"
import {Howl} from "howler";
import song from "../../assets/song/Lazy-Dreaming.mp3";

export default class GUI {

    constructor(_mainCamera, _objects) {
        this.defaultVolume = 0.04
        this.lowVolume = 0.02
        this.userBoat = _objects.boat
        this.hasAlreadyPlayed = false
        this.mainCamera = _mainCamera
        this.setCurrentIsland(null)
        this.gui = document.querySelector('#gui')
        this.sound = new Howl({
            src: [song],
            loop: true,
            volume: this.defaultVolume,
        })
        this.compass = this.gui.querySelector('#js-synergCompassArrow')
        this.audioButton = this.gui.querySelector('#js-audioButton')
        this.contentView = new ContentView(_objects, _ => {
            this.sound.fade(this.lowVolume, this.defaultVolume, 3000)
            this.mainCamera.leaveIsland()
            if (this.currentIsland) {
                this.islandPanel.classList.add('open')
            }
            this.gui.classList.remove('hidden')
        })
        this.islandPanel = this.gui.querySelector('#js-islandPanel')
        this.islandPanelTitle = this.islandPanel.querySelector('#js-islandTitle')
        this.islandPanel.querySelector('#js-islandLink').addEventListener('click', e => {
            e.preventDefault()
            this.handleKeyboardAction(ACTIONS.OPEN)
        })
        this.audioButton.addEventListener('click', _ => {
            this.audioButton.classList.toggle('paused')
            if(this.audioButton.classList.contains('paused'))
            {
                this.sound.fade(this.defaultVolume, 0, 1000)
                let wait = setTimeout(()=>{this.sound.pause()},1000)
            }
            else
                {
                    this.sound.play()
                    this.sound.fade(0, this.defaultVolume, 2000)
                }
        })
        if (CONFIG.DEV_PAGE != null) {
            this.setCurrentIsland(CONFIG.DEV_PAGE)
            this.handleKeyboardAction(ACTIONS.OPEN)
            this.hasAlreadyPlayed = true
        }
    }

    setCurrentIsland(_island) {
        this.currentIsland = _island
        this.userBoat.currentIslandDidChange(_island)
    }

    update(_radian, _position) {
        const compassRotation = _radian - (Math.PI * 0.4)
        this.compass.style.transform = `rotate(${compassRotation}rad)`
        if (this.hasAlreadyPlayed) {
            this.gui.classList.add('userHasAlreadyInteract')
        }
        this.updateIslandPanel(_position)
    }

    updateIslandPanel(_position) {
        if (_position == null) { return }
        if (this.contentView.island && this.contentView.island.id == 'bottle') { return }
        if (this.currentIsland != null) {
            if (this.currentIsland.area.minX < _position.x
                && this.currentIsland.area.maxX > _position.x
                && this.currentIsland.area.minZ < _position.z
                && this.currentIsland.area.maxZ > _position.z) {
                return
            }
            this.contentView.close()
            this.setCurrentIsland(null)
            this.islandPanel.classList.remove('open')
            return
        }
        for (const island of ISLANDS) {
            if (MathHelper.isPointInRectangle(_position, island.area)) {
                this.setCurrentIsland(island)
                if (island.interactive) {
                    this.islandPanelTitle.innerText = island.name
                    this.islandPanel.classList.add('open')
                }
                break
            }
        }
    }

    handleClick() {
        this.hasAlreadyPlayed = true
    }

    launchAudio() {
        this.sound.play()
        this.sound.fade(0, this.defaultVolume, 2000)
    }

    handleKeyboardAction(action) {
        if (action == ACTIONS.OPEN && this.currentIsland != null && this.currentIsland.interactive && !this.contentView.island) {
            this.openContentView(this.currentIsland)
        }
        if (action == ACTIONS.EXIT && !this.islandPanel.classList.contains('open')) {
            this.contentView.launchClosing()
        }
        this.hasAlreadyPlayed = true
    }

    openContentView(_island) {
        this.sound.fade(this.defaultVolume, this.lowVolume, 3000)
        this.islandPanel.classList.remove('open')
        this.gui.classList.add('hidden')
        this.userBoat.stop()
        this.mainCamera.setFocusIsland(_island.focusViewPoint)
        setTimeout(_ => {
            this.contentView.open(_island)
        }, 600)
    }

}