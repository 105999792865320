import RetrospectivePage from './../content/RetrospectivePage'
import NewMandatePage from './../content/NewMandatePage'
import SpeechPage from './../content/SpeechPage'
import GuestBookPage from './../content/GuestBookPage'
import CustomizationPage from './../content/CustomizationPage'

const ISLANDS = [
    {
        id: 'retrospective',
        interactive: true,
        name: 'La rétrospective 2020-2021',
        scrollingView: true,
        area: {
            minX: -38.5,
            maxX: 5.7,
            minZ: 13.3,
            maxZ: 44.5
        },
        solid: {
            type: 'rectangle',
            minX: -28.5,
            maxX: -4.3,
            minZ: 22.3,
            maxZ: 34.5
        },
        focusViewPoint: {
            x: -16.4,
            z: 28.4,
            y: 10
        },
        startScript: _ => (new RetrospectivePage())
    },
    {
        id: 'new-mandate',
        interactive: true,
        name: 'Le nouveau mandat 2021-2022',
        scrollingView: true,
        area: {
            minX: -12,
            maxX: 12,
            minZ: -11,
            maxZ: 9
        },
        solid: {
            type: 'polygone',
            x: 0,
            z: 0,
            rotation: -0.54,
            corners: [
                {x: 2.3, z: -7.5},
                {x: 5.75, z: -5.8},
                {x: 0, z: 3.85},
                {x: -3.3, z: 6.3},
                {x: -4.4, z: 5.6},
                {x: -3.8, z: 1.7},
            ],
        },
        focusViewPoint: {
            x: 0,
            z: 0,
            y: 10
        },
        startScript: _ => (new NewMandatePage())
    },
    {
        id: 'speech',
        interactive: true,
        name: 'Les discours',
        scrollingView: false,
        area: {
            minX: -1.2,
            maxX: 43,
            minZ: -44.8,
            maxZ: -15.6
        },
        solid: {
            type: 'rectangle',
            minX: 8.8,
            maxX: 33,
            minZ: -34.8,
            maxZ: -23.6
        },
        focusViewPoint: {
            x: 20.9,
            z: -29.2,
            y: 10
        },
        startScript: _ => (new SpeechPage())
    },
    {
        id: 'guestbook',
        interactive: true,
        name: 'Le livre d\'or',
        scrollingView: false,
        area: {
            minX: 21.5,
            maxX: 57.5,
            minZ: 5,
            maxZ: 31.5
        },
        solid: {
            type: 'rectangle',
            minX: 30.5,
            maxX: 48.8,
            minZ: 15.7,
            maxZ: 27.5
        },
        focusViewPoint: {
            x: 39.5,
            z: 21,
            y: 10
        },
        startScript: _objects => (new GuestBookPage(_objects.contentView))
    },
    {
        id: 'customization',
        interactive: true,
        name: 'Le garage à péniche',
        scrollingView: false,
        area: {
            minX: -54,
            maxX: -19,
            minZ: -54,
            maxZ: -20
        },
        solid: {
            type: 'rectangle',
            minX: -47.25,
            maxX: -27.25,
            minZ: -47.75,
            maxZ: -27.75
        },
        focusViewPoint: {
            x: -36.5,
            z: -37,
            y: 2.5
        },
        startScript: _objects => (new CustomizationPage(_objects.boat))
    },
    {
        id: 'eiffel-tower',
        interactive: false,
        area: {
            minX: -22.45,
            maxX: -13.95,
            minZ: -4.45,
            maxZ: 4.25
        },
        solid: {
            type: 'rectangle',
            minX: -19.45,
            maxX: -16.95,
            minZ: -1.25,
            maxZ: 1.25
        },
    },
    {
        id: 'unicorn',
        interactive: false,
        area: {
            minX: 15.745,
            maxX: 23.285,
            minZ: -3.77,
            maxZ: 3.77
        },
        solid: {
            type: 'rectangle',
            minX: 18.745,
            maxX: 20.285,
            minZ: -0.77,
            maxZ: 0.77
        },
    },
]

export default ISLANDS