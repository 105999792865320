import BOTTLE from "../constants/BOTTLE"
import ISLANDS from "../constants/ISLANDS"
import * as THREE from 'three'
import BottlePage from "../content/BottlePage"
import MathHelper from "../managers/MathHelper"
const FOCUSED_BOTTLE_Y = 5

export default class Bottles {
    
    constructor(_scene, _gltfLoader, _mainCamera, _gui) {
        this.mainCamera = _mainCamera
        this.scene = _scene
        this.gui = _gui
        fetch('https://api.passation.synerghetic.net/comments')
        .then(response => response.json())
        .then(data => {
            this.data = data
            this.loadBottles(data.length, _gltfLoader)
        })
    }
    
    loadBottles(_bottles, _gltfLoader) {
        this.bottlesCount = _bottles
        this.bottles = []
        for (let i = 0; i < this.bottlesCount; i++) {
            const guestbookIsland = ISLANDS[3]
            const x = this.getRandomArbitrary(guestbookIsland.area.minX, guestbookIsland.area.maxX)
            const z = this.getRandomArbitrary(guestbookIsland.area.minZ - 2, guestbookIsland.area.maxZ - 18)
            _gltfLoader.load(BOTTLE.path,
                (gltf) => {
                    const bottle = gltf.scene
                    bottle.position.set(x, -1.5, z)
                    bottle.scale.set(0.5, 0.5, 0.5)
                    bottle.rotation.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5)
                    this.bottles.push(bottle)
                    this.scene.add(bottle)
                })
            }
        }

        handleClick(_mouse) {
            const raycaster = new THREE.Raycaster()
            raycaster.setFromCamera(_mouse.normalizedMouse2D, this.mainCamera.getCamera())
            for (const i in this.bottles) {
                const bottle = this.bottles[i]
                const intersects = raycaster.intersectObject(bottle, true)
                if (intersects.length > 0 && intersects[0].distance > 0 && intersects[0].distance < 25) {
                    bottle.focusFrame = 0
                    bottle.isGoingUp = true
                    const customIsland = {
                        id: 'bottle',
                        interactive: true,
                        name: 'bottle',
                        scrollingView: false,
                        focusViewPoint: {
                            x: bottle.position.x, // x de la bouteille
                            z: bottle.position.z, // z de la bouteille
                            y: FOCUSED_BOTTLE_Y - 2 // À ajuster, le même pour tous
                        },
                        startScript: _ => (new BottlePage(this.data ? this.data[i] : null))
                    }
                    this.gui.openContentView(customIsland)
                    return
                }
            }
        }
        
        getRandomArbitrary(min, max) {
            return Math.random() * (max - min) + min;
        }
        
        update(_elapsedTime, _refreshRateFactor) {
            if (this.bottles && this.bottles.length > 0) {
                for (let i = 0; i < this.bottles.length; i++) {
                    const bottle = this.bottles[i]
                    if (bottle.focusFrame || !isNaN(bottle.focusFrame)) {
                        if (bottle.isGoingUp) {
                            bottle.focusFrame = Math.min(120, bottle.focusFrame + _refreshRateFactor)
                            const t = MathHelper.easeOutElastic(bottle.focusFrame / 120)
                            bottle.position.y =  FOCUSED_BOTTLE_Y * t
                        } else if (this.gui.contentView.island == null) {
                            bottle.focusFrame = Math.max(0, bottle.focusFrame - _refreshRateFactor)
                            const t = MathHelper.easeOutElastic(1 - (bottle.focusFrame / 120))
                            const finalY = Math.sin((_elapsedTime + bottle.focusFrame / 60) * i * .3) * .05 - .6
                            bottle.position.y =  FOCUSED_BOTTLE_Y * (1 - t) + finalY * t
                        }
                        if (bottle.focusFrame == 120) { bottle.isGoingUp = false }
                        if (bottle.focusFrame == 0) { bottle.focusFrame = undefined }
                    } else {
                        bottle.position.y = Math.sin(_elapsedTime * i * .3) * .05 - .6
                    }
                }
            }
        }

}