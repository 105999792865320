const STATS_GRID_CONFIG = {
    LAUNCH_SCROLL_RATIO: 0.75
}

export default class StatsGrid {

    constructor(_contentView) {
        this.contentView = _contentView
        this.onResize()
        this.onScroll()
        this.onScrollCallback = _ => this.onScroll()
        this.contentView.addEventListener('scroll', this.onScrollCallback)
        this.onResizeCallback = _ => this.onResize()
        window.addEventListener('resize', this.onResizeCallback)
    }

    onScroll() {
        const scrollTop = this.contentView.scrollTop
        const upperBound = scrollTop + (this.windowHeight * STATS_GRID_CONFIG.LAUNCH_SCROLL_RATIO)
        const lowerBound = upperBound - (this.windowHeight * 1.1)
        this.steps.forEach( (step, index) => {
            if (upperBound > step.offsetTop) {
                step.elem.classList.add('visible')
                if (step.offsetTop < lowerBound) {
                    step.elem.classList.add('passed')
                } else {
                    step.elem.classList.remove('passed')
                }
            } else {
                step.elem.classList.remove('visible')
                step.elem.classList.remove('passed')
            }
        })
    }

    onResize() {
        this.windowHeight = this.contentView.offsetHeight
        this.steps = [...document.querySelectorAll('.statsGrid .elem')].map(elem => {
            return {
                offsetTop: elem.offsetTop,
                elem: elem
            }
        })
    }

    kill() {
        this.contentView.removeEventListener('scroll', this.onScrollCallback)
        window.removeEventListener('resize', this.onResizeCallback)
    }

}