const EVENTS = {
    ON: {
        INIT: 'init',
        BARGE_UPDATE: 'barge_position_update',
        BARGE_DISCONNECTED: 'barge_disconnected',
        BARGE_STYLE_UPDATE: 'barge_style_update',
    },
    EMIT: {
        NEW_BARGE: 'new_barge',
        UPDATE_POSITION: 'update_my_position',
        UPDATE_STYLE: 'update_barge_style',
    },
}

export default EVENTS