import BOAT_PARTS from "../constants/BOAT_PARTS"

export default class CustomizationPage {

    constructor(_boat) {
        this.boat = _boat
        this.primaryColorButtons = document.querySelectorAll('#js-primaryColorPicker .js-color')
        this.secondaryColorButtons = document.querySelectorAll('#js-secondaryColorPicker .js-color')
        this.flagButton = document.querySelector('#js-flagInputButton')
        this.flagInput = document.querySelector('#flagInput')
        this.flagButtonCallback = _ => this.flagInput.click()
        this.flagButton.addEventListener('click', this.flagButtonCallback)
        this.flagInputCallback = _ => {
            if (this.flagInput.files.length == 0) { return }
            this.boat.updateBoatMaterial(BOAT_PARTS.FLAG, this.flagInput.files[0])
            this.flagButton.innerHTML = 'Changer mon étendard'
        }
        this.flagInput.addEventListener('change', this.flagInputCallback)
        this.colorsButton = [...this.primaryColorButtons, ...this.secondaryColorButtons]
        this.colorClickCallback = e => this.updateColor(e)
        this.colorsButton.forEach(color => color.addEventListener('click', this.colorClickCallback))
        this.setColorButtons()
    }

    updateColor(_e) {
        const colorButton = _e.target
        const colorPicker = colorButton.parentElement
        const currentColorPicker = colorPicker.querySelector('.current')
        if (currentColorPicker) {
            currentColorPicker.classList.remove('current')
        }
        colorButton.classList.add('current')
        const type = colorPicker.getAttribute('data-type')
        const color = window.getComputedStyle(colorButton).backgroundColor
        this.boat.updateBoatMaterial(type, color)
    }

    setColorButtons() {
        const primaryColor = this.boat.getBoatMaterial(BOAT_PARTS.PRIMARY)
        if (primaryColor) {
            this.primaryColorButtons.forEach(colorButton => {
                const color = window.getComputedStyle(colorButton).backgroundColor
                if (color == primaryColor) {
                    colorButton.classList.add('current')
                } else {
                    colorButton.classList.remove('current')
                }
            })
        }
        const secondaryColor = this.boat.getBoatMaterial(BOAT_PARTS.SECONDARY)
        if (secondaryColor) {
            this.secondaryColorButtons.forEach(colorButton => {
                const color = window.getComputedStyle(colorButton).backgroundColor
                if (color == secondaryColor) {
                    colorButton.classList.add('current')
                } else {
                    colorButton.classList.remove('current')
                }
            })
        }
        if (this.boat.getBoatMaterial(BOAT_PARTS.FLAG)) {
            this.flagButton.innerHTML = 'Changer mon étendard'
        }
    }

    kill() {
        this.colorsButton.forEach(color => color.removeEventListener('click', this.colorClickCallback) )
        this.flagButton.removeEventListener('click', this.flagButtonCallback)
        this.flagInput.removeEventListener('change', this.flagInputCallback)
    }

}