import CAMERA_MODES from "./constants/CAMERA_MODES"
import CONFIG from "./constants/CONFIG"

export default class Intro {
    constructor(_canvas, _mainCamera,gui) {
        this.canvas = _canvas
        this.webgl = document.querySelector(".webgl")
        this.intro = document.querySelector("#intro")
        this.startButton = document.querySelector('.js-start-button')
        this.skipButton = document.querySelector('.js-skip-button')
        this.mainCamera = _mainCamera
        this.ctx = this.canvas.getContext('2d')
        this.colors = ["#FFC107","#59089E","#81D155","#42A5F5"]
        this.balls = []
        this.nbBalls = 0
        this.speed = 1
        this.rain = true
        this.animated = true
        this.playIntro = CONFIG.INTRO_ENABLED
        this.canvas.width = window.innerWidth
        this.canvas.height = window.innerHeight
        this.gui = gui
        this.mobile = this.detectMob();
        this.start()
        this.skipButton.addEventListener('click',()=>{this.skipIntro()})
    }

    start()
    {
        this.resize()
        if(this.playIntro){this.testCookie()}
        this.setNbBalls()
        this.tickChampagne()
        this.animateElements()
    }

    skipIntro()
    {
        this.webgl.classList.add('appear')
        setTimeout(_=>
        {
            this.intro.classList.remove('appearDisplay')
            document.querySelector("#gui").classList.add('appear')
            this.mainCamera.cameraMode = CAMERA_MODES.NAVIGATION
            if(!this.mobile){this.gui.launchAudio()}
            else{this.gui.gui.querySelector('#js-audioButton').classList.add("paused")}

        },1500)
    }

    animateElements() {
        const textOne = this.intro.querySelector(".textOne")
        const textTwo = this.intro.querySelector(".textTwo")
        const textThree = this.intro.querySelector(".textThree")
        const textFour = this.intro.querySelector(".textFour")
        this.intro.classList.add('appearDisplay')
        textOne.classList.add('appear')
        setTimeout(_ => { textOne.querySelector("p:nth-child(1)").classList.add('appear') },2000)
        setTimeout(_ => { textOne.querySelector("p:nth-child(2)").classList.add('appear') },4000)
        setTimeout(_ => { textOne.querySelector("p:nth-child(3)").classList.add('appear') },5500)

        this.startButton.addEventListener('click',
            ()=>
            {
                if(this.playIntro)
                {
                    this.skipButton.classList.add("appear")
                    if(!this.mobile){this.gui.launchAudio()}
                    else{this.gui.gui.querySelector('#js-audioButton').classList.add("paused")}
                    textOne.classList.add('disappear')
                    this.speed = 25
                    this.rain = false
                    setTimeout(_ => {
                        this.canvas.classList.add('disappear')
                        textTwo.classList.add('appear')
                        this.webgl.classList.add('appear')
                    },500)
                    setTimeout(_ => {
                        textTwo.querySelector(".introLogo img").classList.add('scaleUp')
                    },1500)
                    setTimeout(_ => {
                        textTwo.classList.add('disappear')
                    }, 2500)
                    setTimeout(_ => {
                        this.animated = false
                        this.mainCamera.cameraMode = CAMERA_MODES.PLAYING_INTRODUCTION
                    },2450)
                    setTimeout(_ => {
                        textThree.classList.add('appear')
                    }, 3300)
                    setTimeout(_ => {
                        textThree.classList.add('disappear')
                    }, 6800)
                    setTimeout(_ => {
                        textFour.querySelector('.leftText').classList.add('appear')
                    }, 7800)
                    setTimeout(_ => {
                        textFour.querySelector('.leftText').classList.add('disappear')
                        textFour.querySelector('.rightText').classList.add('appear')
                    }, 10800)
                    setTimeout(_ => {
                        textFour.querySelector('.rightText').classList.add('disappear')
                        textFour.classList.add('disappear')
                    }, 13800)
                }
                else
                {
                    this.speed = 25
                    this.rain = false
                    this.skipIntro()
                }

            })
    }

    getCookie(name) {
        const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days) {
        const d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    testCookie()
    {
        if(this.getCookie("viewIntro"))
        {
            this.playIntro = false
        }
        else
        {
            this.setCookie("viewIntro",true,1)
        }
    }

    resize()
    {
        window.addEventListener('resize', () =>
        {
            this.canvas.width = window.innerWidth
            this.canvas.height = window.innerHeight
        })

    }

    setNbBalls()
    {
        this.nbBalls = Math.floor((20*window.innerWidth)/1000)
    }

    draw(color, positionX, positionY, size)
    {
        this.ctx.fillStyle = color
        this.ctx.beginPath();
        this.ctx.arc(positionX, positionY, size, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.closePath();

    }

    detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    tickChampagne() {
        if (!this.animated) { return }
        requestAnimationFrame(_ => this.tickChampagne())
        if (this.balls.length == 0) {
            for(let i = 0; i<this.nbBalls;i++) {
                const ball = new Ball(this)
                this.balls.push(ball)
                this.draw(ball.color,ball.positionX,ball.positionY,ball.size)
            }
            return
        }
        this.ctx.clearRect(0,0, this.canvas.width, this.canvas.height)
        for(let i = 0; i < this.balls.length; i++) {
            if (this.balls[i].tick > this.balls[i].lengthSlideX) {
                this.balls[i].signe = this.balls[i].signe * -1
            } else if (this.balls[i].tick < 0) {
                this.balls[i].signe = this.balls[i].signe * -1
            }
            this.balls[i].positionY -= (1 * this.balls[i].speedY) * this.speed
            this.balls[i].positionX -= (1 * ( this.balls[i].speedX *  (((( (100*this.balls[i].tick)/this.balls[i].lengthSlideX  )-100)/100)+0.5 )))
            this.balls[i].tick += this.balls[i].signe
            this.draw(this.balls[i].color,this.balls[i].positionX,this.balls[i].positionY,this.balls[i].size)
        }
    }

}


class Ball
{
    constructor(canvas) {
        this.sectionSize = window.innerHeight/(Math.round(window.innerWidth/100))
        this.positionX = Math.floor(Math.random()* window.innerWidth)
        this.color = canvas.colors[Math.floor(Math.random()*canvas.colors.length)]

        this.minSize = 40
        this.maxSize = 60

        if(this.color == "#FFC107") {this.minSize = 60; this.maxSize = 80;}
        if(this.color == "#59089E") {this.minSize = 150; this.maxSize = 180;}


        this.size = Math.floor((Math.random() * (this.maxSize - this.minSize))+this.minSize)
        this.lengthSlideX = Math.floor((Math.random()*(this.size*3.5))+(this.size*8.5))
        this.positionY = window.innerHeight-(this.size/2) + Math.floor((Math.random()* (window.innerHeight * 2)))
        this.speedY = (Math.random() * 0.5 ) + 0.2
        this.speedX = (Math.random() * 0.7 ) + 0.6
        this.signe = 1
        this.tick = 0
    }
}


