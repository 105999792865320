import { Color, TextureLoader, RepeatWrapping, MeshStandardMaterial } from 'three'
import CONFIG from '../constants/CONFIG'
import BOAT_MODEL_PATH from './../../models/user_boat_draco.gltf'
import BOAT_PARTS from '../constants/BOAT_PARTS'
import flag from './../../assets/images/synerghetic-flag-default-2021.svg'

export default class SingleBarge {

    constructor(_scene, _gltfLoader, _config = {}, _isUserBarge = false) {
        this.isUserBarge = _isUserBarge
        let boatConfig = _config
        if (boatConfig.style) {
            boatConfig.style.forEach(_styleUpdate => {
                boatConfig[_styleUpdate.property] = _styleUpdate.value
            })
        }
        boatConfig[BOAT_PARTS.PRIMARY] = boatConfig[BOAT_PARTS.PRIMARY] ? boatConfig[BOAT_PARTS.PRIMARY] : 'rgb(244, 241, 249)'
        boatConfig[BOAT_PARTS.SECONDARY] = boatConfig[BOAT_PARTS.SECONDARY] ? boatConfig[BOAT_PARTS.SECONDARY] : 'rgb(89, 8, 158)'
        boatConfig['x'] = boatConfig['x'] ? boatConfig['x'] : CONFIG.BOAT.START_X
        boatConfig['z'] = boatConfig['z'] ? boatConfig['z'] : CONFIG.BOAT.START_Z
        boatConfig['rotY'] = boatConfig['rotY'] ? boatConfig['rotY'] : 0
        this.loadBoat(_scene, _gltfLoader, boatConfig)
    }

    update(_data = null) {
        if (!this.element) { return }
        if (!this.element.visible) {
            if (this.element.position.x != CONFIG.BOAT.START_X && this.element.position.z != CONFIG.BOAT.START_Z) {
                this.element.visible = true
            }
        }
        if (_data) {
            this.element.position.x = _data.x
            this.element.position.z = _data.z
            this.element.rotation.y = _data.rotY
        }
    }

    loadBoat(_scene, _gltfLoader, _boatConfig, _handler = null) {
        _gltfLoader.load(
            BOAT_MODEL_PATH,
            (gltf) => {
                this.element = gltf.scene
                this.configureElement(_boatConfig, _scene)
                _scene.add(this.element)
                this.element.visible = this.isUserBarge
                this.update()
                if (_handler) _handler()
            }
        )
    }

    configureElement(_boatConfig, _scene) {
        this.element.children.forEach( child => {
            switch (child.name) {
                case 'Lantern':
                    child.children[0].intensity = this.isUserBarge ? 0.55 : 0
                    break
                case 'Boat':
                    child.children[0].name = BOAT_PARTS.PRIMARY
                    this.updateBoatMaterial(BOAT_PARTS.PRIMARY, _boatConfig[BOAT_PARTS.PRIMARY])
                    child.children[1].name = BOAT_PARTS.SECONDARY
                    this.updateBoatMaterial(BOAT_PARTS.SECONDARY, _boatConfig[BOAT_PARTS.SECONDARY])
                    child.children[3].name = BOAT_PARTS.FLAG
                default: break
            }
        })
        if (CONFIG.DEV_PAGE != null) {
            const x = (CONFIG.DEV_PAGE.area.minX + CONFIG.DEV_PAGE.area.maxX) / 2
            const y = (CONFIG.DEV_PAGE.area.minZ + CONFIG.DEV_PAGE.area.maxZ) / 2
            this.element.position.set(x, 0, y)
        } else {
            this.element.position.set(_boatConfig.x, 0, _boatConfig.z)
            this.rotation = _boatConfig.rotY
        }
        this.updateBoatMaterial(BOAT_PARTS.FLAG, null)
    }

    getBoatMaterial(_name) {
        if (!this.element) { return null }
        for (const child of this.element.children[1].children) {
            if (child.name == _name) {
                return child.materialData
            }
        }
        return null
    }

    updateBoatMaterial(_name, _data, _fromServer = false) {
        if (!this.element) { return }
        this.element.children[1].children.forEach( child => {
            if (child.name == _name) {
                if (_name == BOAT_PARTS.FLAG) {
                    let texture
                    const loader = new TextureLoader()
                    loader.setCrossOrigin("")
                    if (_data) {
                        const imageUrl = _fromServer ? `${CONFIG.FLAG_SERVER}/${_data}` : URL.createObjectURL(_data)
                        texture = loader.load(imageUrl)
                        child.materialData = 'custom'
                    } else {
                        texture = loader.load(flag)
                    }
                    texture.wrapS = RepeatWrapping
                    texture.repeat.x = - 1
                    child.material = new MeshStandardMaterial({ map: texture })
                } else {
                    child.material = this.getMaterialForHex(_data)
                    child.materialData = _data
                }
            }
        })
    }

    getMaterialForHex(_colorString) {
        return new MeshStandardMaterial({
            color: new Color(_colorString),
            metalness: 0,
            roughness: 1
        })
    }

}