import CONFIG from './../constants/CONFIG'

export default class ContentView {

    constructor(_objects, _closeHandler) {
        this.objects = {..._objects, contentView: this}
        this.view = document.querySelector('#js-contentView')
        this.closeHandler = _closeHandler
        this.island = null
        this.script = null
        this.contentHeight = 0
        this.view.addEventListener('scroll', this.onScroll.bind(this))
        this.onResize()
        window.addEventListener('resize', this.onResize.bind(this))
    }

    open(_island) {
        this.isClosing = false
        this.island = _island
        this.view.classList.add('open')
        if (CONFIG.DEV_PAGE == null) {
            this.view.scrollTop = 0
        }
        fetch(`./../../pages/${_island.id}.html`)
            .then(resp => resp.text())
            .then(text => {
                this.view.classList.add(_island.id)
                this.view.innerHTML = text
                this.contentHeight = this.view.scrollHeight - this.windowHeight
                this.script = _island.startScript(this.objects)
                this.setClosingButtons()
            })

    }

    setClosingButtons() {
        this.closingButtons = document.querySelectorAll('.js-quitContentView')
        this.closingButtonCallback = _ => this.launchClosing()
        this.closingButtons.forEach(button => button.addEventListener('click', this.closingButtonCallback))
    }

    close() {
        if (this.island == null) { return }
        const island = this.island
        this.view.classList.remove('loaded')
        this.view.classList.remove('open')
        if (this.script) {
            this.script.kill()
        }
        this.closingButtons.forEach(button => button.removeEventListener('click', this.closingButtonCallback))
        this.script = null
        this.island = null
        this.view.removeAttribute('style')
        this.closeHandler()
        setTimeout(_ => {
            if (this.island == null || this.island.id != island.id) {
                this.view.classList.remove(island.id)
            }
            if (this.island == null) {
                this.view.innerHTML = ""
            }
        }, 1000)
    }

    launchClosing(_currentOffset = 0) {
        this.view.classList.add('loaded')
        this.isClosing = true
        const remainingOffset = this.windowHeight + _currentOffset
        const frames = 15
        for (let i = 1; i <= frames; i++) {
            setTimeout(_ => {
                this.view.style.transform = `translateY(${_currentOffset - (i * remainingOffset / frames)}px)`
                this.view.style.opacity = 1 - (i / frames)
                if (i == frames) { this.close() }
            }, i * 1000 * 1/60)
        }
    }

    onScroll() {
        if (this.island && !this.island.scrollingView) { return }
        if (!this.isScrollToCloseEnable) { return }
        if(this.isClosing) { return }
        const scrollTop = this.view.scrollTop
        const closingOffset = Math.min(0, this.contentHeight - (scrollTop + this.windowHeight))
        if (scrollTop > 100) {
            this.view.classList.add('loaded')
        }
        const transform = closingOffset == 0 ? 'none' : `translateY(${closingOffset}px)`
        this.view.style.transform = transform
        if (closingOffset < this.windowHeight * -0.5) {
            this.launchClosing(closingOffset)
        }
    }

    onResize() {
        this.windowHeight = this.view.offsetHeight
        this.isScrollToCloseEnable = window.innerWidth > 1080
        this.contentHeight = this.view.scrollHeight - this.windowHeight
    }

}