import * as THREE from 'three'
import CAMERA_MODES from '../constants/CAMERA_MODES'

export default class ClickHandler {

    constructor(_scene, _canvas, _camera, _sea, _gui) {
        this.gui = _gui
        this.subscribers = []
        document.addEventListener('click', _e => this.clickEvent(_e, _camera, _sea))
    }

    clickEvent(_e, _camera, _sea) {
        if (_camera.cameraMode != CAMERA_MODES.NAVIGATION) { return }
        if (this.gui.contentView.island != null) { return }
        if (_e.target instanceof HTMLAnchorElement || _e.target.parentElement instanceof HTMLAnchorElement || _e.target.parentElement.parentElement instanceof HTMLAnchorElement) { return }
        const normalizedMouse2D = {
            x: ( _e.clientX / window.innerWidth ) * 2 - 1,
            y: -( _e.clientY / window.innerHeight ) * 2 + 1
        }
        var vector = new THREE.Vector3(
            normalizedMouse2D.x,
            normalizedMouse2D.y,
            0.5
        )
        const cameraElem = _camera.getCamera()
        vector.unproject(cameraElem)
        const raycaster = new THREE.Raycaster(cameraElem.position, vector.sub( cameraElem.position ).normalize())
        const intersects = raycaster.intersectObject(_sea.element, false)
        if (intersects.length > 0) {
            const vector = new THREE.Vector3().copy( intersects[ 0 ].point )
            intersects[ 0 ].object.worldToLocal( vector );
            this.sendClickTo({
                x: vector.x,
                z: -vector.y,
                normalizedMouse2D: normalizedMouse2D
            })
        }
    }

    sendClickTo(_vector) {
        this.subscribers.forEach(sub => {
            sub.handleClick(_vector)
        })
    }

    newSubscriber(_sub) {
        this.subscribers.push(_sub)
    }

}