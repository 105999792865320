import ACTIONS from "../constants/ACTIONS"
import CAMERA_MODES from "../constants/CAMERA_MODES"

export default class ActionsHandler {

    constructor(_gui, _camera) {
        this.gui = _gui
        this.camera = _camera
        this.pressedActions = []
        this.isBoostKeyDown = false
        this.subscribers = []
        document.addEventListener('keydown', e => {
            switch (e.code) {
                case 'ArrowUp':
                case 'KeyW':
                    this.saveActiveAction(ACTIONS.UP, e.code)
                    break
                case 'ArrowDown':
                case 'KeyS':
                    this.saveActiveAction(ACTIONS.DOWN, e.code)
                    break
                case 'ArrowRight':
                case 'KeyD':
                    this.saveActiveAction(ACTIONS.RIGHT, e.code)
                    break
                case 'ArrowLeft':
                case 'KeyA':
                    this.saveActiveAction(ACTIONS.LEFT, e.code)
                    break
                case 'KeyQ':
                    this.saveActiveAction(ACTIONS.BREAK, e.code)
                    break
                case 'Space':
                    this.sendAction(ACTIONS.OPEN)
                    break
                case 'Escape':
                    this.sendAction(ACTIONS.EXIT)
                    break
                case 'ShiftLeft':
                case 'ShiftRight':
                    this.isBoostKeyDown = true
                    break
                default: break;
            }
        })
        document.addEventListener('keyup', e => {
            this.pressedActions = this.pressedActions.filter(action => action.code != e.code )
            if (e.code == 'ShiftLeft' || e.code == 'ShiftRight') {
                this.isBoostKeyDown = false   
            }
        })
    }

    saveActiveAction(_action, _code) {
        if (this.gui.contentView.island != null) {
            this.pressedActions = []
            return
        }
        if (this.pressedActions.some(action => action.code == _code)) { return }
        this.pressedActions.push({
            name: _action,
            code: _code
        })
    }

    sendAction(_action) {
        if (this.camera.cameraMode != CAMERA_MODES.NAVIGATION && this.camera.cameraMode != CAMERA_MODES.CONTENT_READING) { return }
        this.subscribers.forEach(sub => {
            sub.handleKeyboardAction(_action, this.isBoostKeyDown)
        })
    }

    newSubscriber(_sub) {
        this.subscribers.push(_sub)
    }

    update() {
        this.pressedActions.forEach(action => {
            this.sendAction(action.name)
        })
    }

}