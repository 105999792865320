import * as THREE from 'three'
import { Vector3 } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import CAMERA_MODES from '../constants/CAMERA_MODES'
import CONFIG from './../constants/CONFIG'
import ISLANDS from './../constants/ISLANDS'

export default class MainCamera {

    constructor(_scene, _canvas, _aspectRatio) {
        this.cameraMode = CAMERA_MODES.WAITING_INTRODUCTION
        this.introProgression = 20
        this.camera = new THREE.PerspectiveCamera(this.computeFOV(_aspectRatio), _aspectRatio, 0.1, CONFIG.MAP_DEBUG ? 500 : 200)
        this.camera.position.set(0, 88, 0)
        this.camera.updateProjectionMatrix()
        _scene.add(this.camera)
        this.setCameraPathSpline(_scene)
        this.lastLookAtPoint = new THREE.Vector3()
        if (CONFIG.MAP_DEBUG) {
            const cameraHelper = new THREE.CameraHelper(this.camera)
            _scene.add(cameraHelper)
            this.debugCamera = new THREE.PerspectiveCamera(this.computeFOV(_aspectRatio), _aspectRatio, 0.1, CONFIG.MAP_DEBUG ? 500 : 200)
            this.debugCamera.position.set(0, 72, 0)
            this.debugCamera.updateProjectionMatrix()
            _scene.add(this.debugCamera)
            this.controls = new OrbitControls(this.debugCamera, _canvas)
            _canvas.classList.add('debug')
            ISLANDS.forEach(island => {
                const zoneGeometry = new THREE.BoxGeometry(
                    island.area.maxX - island.area.minX,
                    0.2,
                    island.area.maxZ - island.area.minZ)
                const zoneMesh = new THREE.Mesh(zoneGeometry, new THREE.MeshNormalMaterial())
                zoneMesh.position.set((island.area.maxX + island.area.minX)/2, 0, (island.area.maxZ + island.area.minZ)/2)
                _scene.add(zoneMesh)
                if (island.solid.type == 'rectangle') {
                    const solidGeometry = new THREE.BoxGeometry(island.solid.maxX - island.solid.minX, 1, island.solid.maxZ - island.solid.minZ)
                    const solidMesh = new THREE.Mesh(solidGeometry, new THREE.MeshNormalMaterial())
                    solidMesh.position.set((island.solid.maxX + island.solid.minX)/2, 0, (island.solid.maxZ + island.solid.minZ)/2)
                    _scene.add(solidMesh)
                } else {
                    const shape = new THREE.Shape()
                    const lastCorner = island.solid.corners[island.solid.corners.length - 1]
                    shape.moveTo( lastCorner.x, lastCorner.z )
                    island.solid.corners.forEach(corner => {
                        shape.lineTo(corner.x, corner.z)
                    })
                    const geometry = new THREE.ExtrudeGeometry( shape, {depth: 20, bevelSize: 0})
                    const solidMesh = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial())
                    solidMesh.rotation.x = Math.PI * 0.5
                    solidMesh.position.set(island.solid.x, 0, island.solid.z)
                    _scene.add(solidMesh)
                }
            })
        }
    }

    setCameraPathSpline(_scene) {
        const spline = new THREE.CatmullRomCurve3( [
            new THREE.Vector3(0, 72, 0),
            new THREE.Vector3(0, 32, -8),
            new THREE.Vector3(20, 16, -16),
            new THREE.Vector3(32, 12, -8),
            new THREE.Vector3(28, 11, 10),
            new THREE.Vector3(16, 10, 12),
            new THREE.Vector3(0, 8, 0),
            new THREE.Vector3(-19, 6, -10),
            new THREE.Vector3(-38, 8, -8),
        ])
        this.cameraSplineGeometry = new THREE.TubeGeometry( spline, 100, 2, 2, false )
        if (CONFIG.MAP_DEBUG) {
            const material = new THREE.MeshLambertMaterial( { color: 0xff00ff } )
            const mesh = new THREE.Mesh( this.cameraSplineGeometry, material )
            _scene.add( mesh )
        }
    }

    getCamera() {
        return CONFIG.MAP_DEBUG ? this.debugCamera : this.camera
    }

    setFocusIsland(_fp) {
        this.cameraMode = CAMERA_MODES.CONTENT_READING
        this.islandInitialQuaternion = this.camera.quaternion.clone()
        const rotationMatrix = new THREE.Matrix4()
        rotationMatrix.lookAt(this.camera.position, new Vector3(_fp.x, _fp.y, _fp.z), this.camera.up)
        this.quaternionRotationProgression = 0
        this.islandTargetQuaternion = new THREE.Quaternion()
        this.islandTargetQuaternion.setFromRotationMatrix(rotationMatrix)
    }

    updateCameraContentReadingMode() {
        this.rotateCameraTowards(true, 120)
    }

    rotateCameraTowards(_in, _frames) {
        const quaternion = _in ? this.islandTargetQuaternion : this.islandInitialQuaternion
        if (!quaternion) { return false }
        this.quaternionRotationProgression++
        const speedBoost = Math.min((_frames/4),Math.max(- (_frames/4), 2 * (Math.abs(this.quaternionRotationProgression - (_frames/2)) - (_frames/4))))
        this.camera.quaternion.rotateTowards(quaternion, Math.PI/(_frames + speedBoost))
        if (this.camera.quaternion.equals(quaternion)) {
            if (_in) {
                this.islandTargetQuaternion = null
                this.quaternionRotationProgression = 0
            } else {
                this.islandInitialQuaternion = null
            }
        }
        return true
    }

    leaveIsland() {
        this.cameraMode = CAMERA_MODES.NAVIGATION
        this.islandTargetQuaternion = null
    }

    update(_boat, _refreshRateFactor = 1) {
        if (CONFIG.MAP_DEBUG) {
            this.controls.update()
        }
        switch (this.cameraMode) {
            case CAMERA_MODES.NAVIGATION:
                this.updateCameraNavigationMode(_boat)
                break
            case CAMERA_MODES.CONTENT_READING:
                this.updateCameraContentReadingMode()
                break
            case CAMERA_MODES.PLAYING_INTRODUCTION:
                this.updateCameraIntroductionFlow(_refreshRateFactor)
                break
            default:
                this.camera.position.set(0, 72, 0)
                this.camera.rotation.x = Math.PI * -0.5
                this.camera.rotation.z = Math.PI
                break
        }
    }

    updateCameraIntroductionFlow(_refreshRateFactor) {
        const direction = new THREE.Vector3()
        const binormal = new THREE.Vector3()
        const normal = new THREE.Vector3()
        const lookAt = new THREE.Vector3()
        const position = new THREE.Vector3()

        this.introProgression += 1 * _refreshRateFactor
        const t = Math.max(0, this.introProgression / (CONFIG.MAP_DEBUG ? 300 : 900))
        if (t >= 1) {
            document.querySelector("#gui").classList.add('appear-long')
            document.querySelector("#intro").classList.remove('appearDisplay')
            this.cameraMode = CAMERA_MODES.NAVIGATION
            return
        }
        // interpolation
        this.cameraSplineGeometry.parameters.path.getPointAt(t, position)
        const segments = this.cameraSplineGeometry.tangents.length
        const pickt = t * segments
        const pick = Math.floor(pickt)
        const pickNext = (pick + 1) % segments
        binormal.subVectors(this.cameraSplineGeometry.binormals[pickNext], this.cameraSplineGeometry.binormals[pick])
        binormal.multiplyScalar(pickt - pick).add(this.cameraSplineGeometry.binormals[pick])
        this.cameraSplineGeometry.parameters.path.getTangentAt(t, direction)
        normal.copy(binormal).cross(direction)
        this.camera.position.copy(position)
        // using arclength for stablization in look ahead
        let futur = (t + 30 / this.cameraSplineGeometry.parameters.path.getLength())
        if (futur <= 1) {
            this.cameraSplineGeometry.parameters.path.getPointAt(futur, lookAt)
        } else {
            lookAt.copy(new THREE.Vector3(-38, 8, -8))
        }
        const lookAtState = t * 3
        if (lookAtState < 1) {
            lookAt.multiplyScalar(lookAtState)
        } else if (lookAtState > 2) {
            lookAt.multiplyScalar(3 - lookAtState)
            const dest = new THREE.Vector3(-38, 0, 0).multiplyScalar(1 - (3 - lookAtState))
            const lookAtDest = new THREE.Vector3().addVectors(lookAt, dest)
            lookAt.copy(lookAtDest)
        }
        if (this.lastLookAtPoint) {
            lookAt.copy(new THREE.Vector3(
                this.lastLookAtPoint.x + Math.max(-0.2, Math.min(0.2, lookAt.x - this.lastLookAtPoint.x)),
                this.lastLookAtPoint.y + Math.max(-0.2, Math.min(0.2, lookAt.y - this.lastLookAtPoint.y)),
                this.lastLookAtPoint.z + Math.max(-0.2, Math.min(0.2, lookAt.z - this.lastLookAtPoint.z))
            ))
        }
        this.lastLookAtPoint.copy(lookAt)
        this.camera.matrix.lookAt(position, lookAt, t <= 0 ? new THREE.Vector3(0, -1, 0) : this.camera.up)
        this.camera.quaternion.setFromRotationMatrix(this.camera.matrix)
    }

    updateCameraNavigationMode(_boat) {
        if (!_boat.element) {
            this.camera.position.set(0, 88, 0)
            this.camera.rotation.x = Math.PI * -0.5
            return
        }
        if (this.rotateCameraTowards(false, 105)) { return }
        const normalizedBoatRot = _boat.rotation - Math.PI/2
        const xDest = _boat.element.position.x - (8 * Math.cos(normalizedBoatRot))
        const zDest = _boat.element.position.z + (8 * Math.sin(normalizedBoatRot))
        const yDest = _boat.element.position.y + 3 + 4 * (1 - _boat.speed)
        const x = this.camera.position.x + (xDest - this.camera.position.x) * 0.03
        const z = this.camera.position.z + (zDest - this.camera.position.z) * 0.03
        const y = this.camera.position.y + (yDest - this.camera.position.y) * 0.05
        this.camera.position.set(x, y, z)
        this.camera.lookAt(_boat.element.position)
    }

    updateOnResize(_ratio) {
        const camera = this.getCamera()
        camera.fov = this.computeFOV(_ratio)
        camera.aspect = _ratio
        camera.updateProjectionMatrix()
    }

    computeFOV(_ratio) {
        const extension = _ratio >= 1 ? 0 : 1 - _ratio
        return 75 + (40 * extension)
    }

}