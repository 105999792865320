import scene from './../../models/scene/scene-synerg-d.glb'
import mainSceneTexture from './../../models/scene/main-scene_8-c80.jpg'
import mainSceneIslandsTexture from './../../models/scene/main-scene-general_8-c80.jpg'

const SCENE_MODEL = {
    name: 'scene',
    path: scene,
    mainSceneTexture: mainSceneTexture,
    mainSceneIslandsTexture: mainSceneIslandsTexture,
}

export default SCENE_MODEL