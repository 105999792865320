
export default class GuestBookPage {

    constructor(_contentView) {
        this.contentView = _contentView
        this.cursorElement = document.querySelector('.js-slide-cursor')
        this.slidebar = document.querySelector('.js-slidebar')
        this.container = document.querySelector('.container')
        this.navigateElement = document.querySelector('.to_navigate')
        this.proseElement = document.querySelector('.to_prose')
        this.messageBtn = document.querySelector('.js-put-in-bottle-btn')
        this.signatureBtn = document.querySelector('.js-signature-btn')
        this.comment = document.querySelector('#form-message')
        this.signature = document.querySelector('#form-signature')
        this.closeBtn = document.querySelector('.js-back-to-sea')


        this.bounding = this.slidebar.getBoundingClientRect()
        this.size = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        this.slidePos = 0
        this.slideRatio = - 100 / 4

        // Create callback constants
        this.draggingCallback = e => this.dragging(e)
        this.mouseDownCallback = _ => this.mouseDown()
        this.dropCallback = _ => this.drop()
        this.pressCallback = _ => this.removeFocus()

        // this.clickCallback = _ => this.toSignature()
        // Add them
        this.cursorElement.addEventListener('mousedown', this.mouseDownCallback)
        this.cursorElement.addEventListener('touchstart', this.mouseDownCallback)

        this.toSignature()
        this.handleSignature()

        this.closeBtn.addEventListener('click', this.close.bind(this))

    }


    mouseDown() {
        document.addEventListener('mousemove', this.draggingCallback)
        document.addEventListener('touchmove', this.draggingCallback)
    }

    dragging(_e) {
        let clientX;
        _e.type === 'mousemove' 
        ? clientX = Math.min(this.bounding.right, (Math.max(this.bounding.left, _e.clientX )))
        : clientX = Math.min(this.bounding.right, (Math.max(this.bounding.left, _e.touches[0].clientX )))
        this.x = (clientX / this.size.width - 0.5) * this.size.width
        this.cursorElement.style.transform = `translateX(${this.x}px)`

        document.addEventListener('mouseup', this.dropCallback)
        document.addEventListener('touchend', this.dropCallback)

    }

    drop() {
        document.removeEventListener('mousemove', this.draggingCallback)
        document.removeEventListener('touchmove', this.draggingCallback)
        this.proseOrNavigate()
        document.removeEventListener('mouseup', this.dropCallback)
        document.removeEventListener('touchend', this.dropCallback)
    }

    slide() {
        this.slidePos ++
        this.container.style.transform = `translate(${this.slidePos * this.slideRatio}%)`
    }

    proseOrNavigate() {
        const navigateBounding = this.navigateElement.getBoundingClientRect();
        const proseBounding = this.proseElement.getBoundingClientRect();
        const cursorBounding = this.cursorElement.getBoundingClientRect();


        if(cursorBounding.right >= proseBounding.left) {
            this.slide()
        } else if(navigateBounding.right >= cursorBounding.left ) {
            this.close()
        }
        

        this.cursorElement.style.transform = 'translate(0px)'
    }

    close() {
        this.contentView.launchClosing()
    }

    checkLength(_el) {
        const isFilled = _el.value.length > 0
        return isFilled
    }

    toSignature() {
        this.messageBtn.addEventListener('click', () => {
            this.checkLength(this.comment) ? this.slide() : this.comment.focus();
        })
    }

    

    handleCommentInfo() {
        const content = this.comment.value
        const signature = this.signature.value

        const commentInfo = {
            content, 
            signature
        }
        return commentInfo;
    }

    handleSignature() {
        this.signatureBtn.addEventListener('click', ()=> {
            this.checkLength(this.signature) ? this.postComment() : this.signature.focus();
        })
    }

    postComment() {
            const commentToPost = {...this.handleCommentInfo()}
            fetch('https://api.passation.synerghetic.net/comments', {
                method: 'post',
                body: JSON.stringify(
                    {
                        content: commentToPost.content, 
                        signature: commentToPost.signature
                    }
                )
            })
            .then(response => {
                response.json()
                this.slide()
            })
    }


    kill() {
        document.removeEventListener('keydown', this.pressCallback)
        document.removeEventListener('mousemove', this.draggingCallback)
        document.removeEventListener('mousedown', this.mouseDownCallback)
    }
}