import * as THREE from 'three'
import * as dat from 'dat.gui'
import UserBarge from './objects/UserBarge'
import Sea from './objects/Sea'
import ActionsHandler from './managers/ActionsHandler'
import MainCamera from './managers/MainCamera'
import ClickHandler from './managers/ClickHandler'
import SCENE_MODEL from './constants/SCENE_MODEL'
import GUI from './managers/GUI'
import CONFIG from './constants/CONFIG'
import Stats from 'stats.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import Multibarges from './managers/Multibarges'
import Barges from './objects/Barges'
import MathHelper from './managers/MathHelper'
import Bottles from './objects/Bottles'
import { MeshBasicMaterial } from 'three'

// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'

/**
* Base
*/

export default class Scene {
    constructor() {
        if (CONFIG.MAP_DEBUG) {
            this.datGUI = new dat.GUI()
        }
        this.canvas = document.querySelector('canvas.webgl')
        // Loader
        this.gltfLoader = new GLTFLoader()
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('https://cdn.jsdelivr.net/npm/three@0.123.0/examples/js/libs/draco/')
        this.gltfLoader.setDRACOLoader(dracoLoader)
        // Scene
        this.scene = new THREE.Scene()
        this.scene.background = new THREE.Color(0x000933)
        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas
        })
        // Multiplayers
        this.barges = new Barges(this.scene, this.gltfLoader)
        this.multibarges = new Multibarges()
        this.userBarge = new UserBarge(this.scene, this.gltfLoader, this.multibarges)
        this.sea = new Sea(this.scene)
        this.handleResize()
        this.mainCamera = new MainCamera(this.scene, this.canvas, this.sizes.width / this.sizes.height)
        this.clickedBotlle = false
        this.addRenderer()
        
        this.gui = new GUI(this.mainCamera, {
            boat: this.userBarge
        })
        
        // Actions
        this.multibarges.newSubscriber(this.userBarge)
        this.multibarges.newSubscriber(this.barges)
        this.actionsHandler = new ActionsHandler(this.gui, this.mainCamera)
        this.actionsHandler.newSubscriber(this.userBarge)
        this.actionsHandler.newSubscriber(this.gui)
        this.clickHandler = new ClickHandler(this.scene, this.canvas, this.mainCamera, this.sea, this.gui)
        this.clickHandler.newSubscriber(this.userBarge)
        this.clickHandler.newSubscriber(this.gui)
        this.bottles = new Bottles(this.scene, this.gltfLoader, this.mainCamera, this.gui)
        this.clickHandler.newSubscriber(this.bottles)
        
        this.loadScene()
        
        this.addLights()
        // this.postProcess()
        if (!CONFIG.IS_ONLINE) {
            this.stats = new Stats()
            this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
            document.body.appendChild(this.stats.dom)
        }
        this.clock = new THREE.Clock()
        this.addTick()
    }
    
    addLights() {
        const primaryLight = new THREE.DirectionalLight(0xFF9F7E, .4)
        primaryLight.position.set(-5, 2, -5)
        const backLight = new THREE.DirectionalLight(0xFFB2DF, .08)
        backLight.position.set(5, 1.5, 10)
        
        this.scene.add(primaryLight)
        this.scene.add(backLight)
        
        if (CONFIG.MAP_DEBUG) {
            const helperPrimaryLight = new THREE.DirectionalLightHelper( primaryLight, 10 );
            this.scene.add( helperPrimaryLight );
            const helperBackLight = new THREE.DirectionalLightHelper( backLight, 10 );
            this.scene.add( helperBackLight );
        }
    }
    
    addRenderer() {
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    
    loadScene() {
        const textureLoader = new THREE.TextureLoader()
        
        const mainSceneTexture = textureLoader.load(SCENE_MODEL.mainSceneTexture)
        mainSceneTexture.encoding = THREE.sRGBEncoding
        mainSceneTexture.flipY = false
        const mainSceneMaterial = new THREE.MeshBasicMaterial({ map: mainSceneTexture })
        
        const mainSceneIslandsTexture = textureLoader.load(SCENE_MODEL.mainSceneIslandsTexture)
        mainSceneIslandsTexture.encoding = THREE.sRGBEncoding
        mainSceneIslandsTexture.flipY = false
        const mainSceneIslandsMaterial = new THREE.MeshBasicMaterial({ map: mainSceneIslandsTexture })
        
        this.gltfLoader.load( SCENE_MODEL.path,
            (gltf) => {
                let buoysPositions = []
                const model = gltf.scene
                model.children.forEach(child => {
                    switch (child.name) {
                        case 'SeaBed':
                        case 'titanic-poster':
                        case 'licorne-poster':
                        case 'emissions':
                        case 'synerg-flag':
                            break
                        case 'main-scene-general':
                            child.traverse(subchild => {
                                if (subchild.material && !subchild.material.map && subchild.material.emissive.r == 0 && subchild.material.emissive.g == 0 && subchild.material.emissive.b == 0) {
                                    subchild.material = mainSceneIslandsMaterial
                                }
                            })
                            break
                        case 'main-scene':
                            child.traverse(subchild => {
                                if (subchild.material && !subchild.material.map && subchild.material.emissive.r == 0 && subchild.material.emissive.g == 0 && subchild.material.emissive.b == 0) {
                                    subchild.material = mainSceneMaterial
                                }
                            })
                            break
                        default:
                            if (child.name.includes('lamp') && !child.name.includes('lamp_light')) {
                                const geo = child.children[1].geometry
                                const bounding = {
                                    id: `${geo.boundingSphere.center.x}_${geo.boundingSphere.center.z}`,
                                    x: geo.boundingSphere.center.x + child.position.x,
                                    z: geo.boundingSphere.center.z + child.position.z,
                                    radius: geo.boundingSphere.radius * 0.5
                                }
                                const indexes = MathHelper.getXZIndexes(bounding)
                                if (!buoysPositions[indexes.x]) { buoysPositions[indexes.x] = [] }
                                if (!buoysPositions[indexes.x][indexes.z]) { buoysPositions[indexes.x][indexes.z] = [] }
                                buoysPositions[indexes.x][indexes.z].push(bounding)
                            }
                            child.traverse(subchild => {
                                if (!subchild.material) { return }
                                // if (subchild.material.name == "body lamp") {
                                //     subchild.material = new MeshBasicMaterial({ color: subchild.material.color})
                                // }
                                if (child.name == "border-buyos" && subchild.material.name != "light of the lamp") {
                                    subchild.material = new MeshBasicMaterial({ color: subchild.material.color.offsetHSL(0, 0, -0.3)})
                                }
                            })
                    }
                })
                this.scene.add(model)
                this.userBarge.configureBuoysCollisions(buoysPositions)
            }
            )
        }
        
        addTick() {
            if (this.stats) {
                this.stats.begin()
            }
            const elapsedTime = this.clock.getElapsedTime()
            const refreshRateFactor = Math.min(4, (this.lastClockElapsedTime != undefined ? (elapsedTime - this.lastClockElapsedTime) : 0) * 60)
            this.lastClockElapsedTime = elapsedTime
            this.bottles.update(elapsedTime, refreshRateFactor)
            // Update controls
            this.actionsHandler.update(this.gui.currentIsland != null)
            this.barges.update()
            this.userBarge.update(this.barges.elementsPositions(), refreshRateFactor)
            this.sea.update()
            this.gui.update(this.userBarge.rotation, this.userBarge.element ? this.userBarge.element.position : null)
            this.mainCamera.update(this.userBarge, refreshRateFactor)
            
            // Render
            this.renderer.render(this.scene, this.mainCamera.getCamera())
            this.renderer.outputEncoding = THREE.sRGBEncoding
            // this.effectComposer.render(this.scene, this.mainCamera.getCamera())
            
            // Call tick again on the next frame
            window.requestAnimationFrame(this.addTick.bind(this))
            
            if (this.stats) {
                this.stats.end()
            }
        }

        handleResize() {
            
            window.addEventListener('resize', () => {
                // Update sizes
                this.sizes.width = window.innerWidth
                this.sizes.height = window.innerHeight
                
                // Update camera
                this.mainCamera.updateOnResize(this.sizes.width / this.sizes.height)
                
                // Update renderer
                this.renderer.setSize(this.sizes.width, this.sizes.height)
                this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            })
        }

}