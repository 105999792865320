import { io } from "socket.io-client"
import BOAT_PARTS from "../constants/BOAT_PARTS"
import CONFIG from "../constants/CONFIG"
import EVENTS from "../constants/EVENTS"

export default class Multibarges {

    constructor() {
        this.isFullyConnected = false
        this.subscribers = []
        if (!CONFIG.MULTIBARGES_ENABLED) { return }
        this.socket = io.connect(CONFIG.SOCKET_SERVER, {transports: ['websocket']})
        this.setupDebugListeners()
        this.setupListeners()
    }

    updateBargeStyle(_property, _value) {
        if (!CONFIG.MULTIBARGES_ENABLED) { return }
        if (_property == BOAT_PARTS.FLAG) {
            let data = new FormData()
            data.append('id', this.socket.id)
            data.append('flag', _value)
            fetch(CONFIG.FLAG_SERVER, { method: 'POST', body: data })
                .then(response => response.json())
                .then(json => {
                    if (!json.success) { return }
                    this.socket.emit(EVENTS.EMIT.UPDATE_STYLE, { property: _property, value: json.filename })
                })
                .catch(error => {
                    console.log('Post flag failed : ', error)
                })
        } else {
            this.socket.emit(EVENTS.EMIT.UPDATE_STYLE, {
                property: _property,
                value: _value
            })
        }
    }

    updateBarge(_data) {
        if (!CONFIG.MULTIBARGES_ENABLED) { return }
        const event = this.isFullyConnected ? EVENTS.EMIT.UPDATE_POSITION : EVENTS.EMIT.NEW_BARGE
        this.socket.emit(event, _data)
        this.isFullyConnected = true
    }

    setupListeners() {
        this.socket.on(EVENTS.ON.INIT, _allBargePositions => {
            this.sendEvent(EVENTS.ON.INIT, _allBargePositions)
        })
        this.socket.on(EVENTS.ON.BARGE_UPDATE, _bargePosition => {
            this.sendEvent(EVENTS.ON.BARGE_UPDATE, _bargePosition)
        })
        this.socket.on(EVENTS.ON.BARGE_STYLE_UPDATE, _bargeStyleUpdate => {
            this.sendEvent(EVENTS.ON.BARGE_STYLE_UPDATE, _bargeStyleUpdate)
        })
        this.socket.on(EVENTS.ON.BARGE_DISCONNECTED, _bargeId => {
            this.sendEvent(EVENTS.ON.BARGE_DISCONNECTED, _bargeId)
        })
    }

    sendEvent(_event, _data) {
        this.subscribers.forEach(sub => {
            sub.handleMultibargesEvent(_event, _data, this.socket.id)
        })
    }

    newSubscriber(_sub) {
        this.subscribers.push(_sub)
    }

    setupDebugListeners() {
        if (!CONFIG.SOCKET_DEBUG) { return }
        this.socket.on("connect", () => console.log("connect : ", this.socket.id))
        this.socket.on("disconnect", () => console.log("disconnect : ", this.socket.id))
        this.socket.on("connect_error", (connect_error) => console.log("connect_error : ", connect_error))
        this.socket.io.on("reconnection_attempt", (reconnection_attempt) => console.log('reconnection_attempt : ', reconnection_attempt))
        this.socket.io.on("reconnect", () => console.log('reconnect'))
    }

}