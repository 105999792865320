import { Howl } from 'howler'
import audio_paul_haddou from './../../assets/speech/discours_paul_haddou.mp3'
import audio_denys_chomel from './../../assets/speech/discours_denys_chomel.mp3'
import audio_julien_wagentrutz from './../../assets/speech/discours_julien_wagentrutz.mp3'
import speech_paul_haddou from './../../assets/speech/speech_paul_haddou.json'
import speech_julien_wagentrutz from './../../assets/speech/speech_julien_wagentrutz.json'
import speech_denys_chomel from './../../assets/speech/speech_denys_chomel.json'
import WheelTouchScreenCompatibleManager from "../managers/WheelTouchScreenCompatibleManager"

const SPEECHES = [
    {
        content: speech_paul_haddou,
        audio: new Howl({ src: audio_paul_haddou })
    },
    {
        content: speech_julien_wagentrutz,
        audio: new Howl({ src: audio_julien_wagentrutz})
    },
    {
        content: speech_denys_chomel,
        audio: new Howl({ src: audio_denys_chomel})
    },
]

export default class SpeechPage {

    constructor() {
        this.isAlive = true
        this.isAudioPlaying = false
        this.isDraggingProgressBar = false
        this.speechDeltaY = 0
        this.speechScrollingTimer = null
        this.contentView = document.querySelector('#js-contentView')
        this.speakersBox = this.contentView.querySelector('#js-speakersBox')
        this.progressBar = this.contentView.querySelector('#js-progressBar')
        this.seekBar = this.progressBar.querySelector('#js-seekbar')
        this.playPauseButton = this.contentView.querySelector('#js-playPauseButton')
        this.initalSpeakers = this.speakersBox.querySelectorAll('.playerCard')
        this.speechScrollingBox = this.contentView.querySelector('#js-speechScrollingBox')
        this.onResize()
        // Speakers scrolling
        this.initalSpeakers.forEach( (speaker, index) => {
            speaker.addEventListener('click', _ => {
                this.goToSpeaker(index)
            })
        })
        // Speech text scrolling
        this.speechWheelHandler = e => this.speechWheel(e.detail.deltaY)
        this.wheelTouchScreenCompatibleManager = new WheelTouchScreenCompatibleManager(this.speechScrollingBox)
        this.speechScrollingBox.addEventListener('wheelTouchScreenCompatible', this.speechWheelHandler)
        // Audio Player
        this.progressBarMouseDownCallback = _ => this.progressBarMouseDown()
        this.progressBar.addEventListener('mousedown', this.progressBarMouseDownCallback )

        this.progressBarMouseMoveCallback = e => this.progressBarMouseMove(e.clientX)
        window.addEventListener('mousemove', this.progressBarMouseMoveCallback)

        this.progressBarMouseUpCallback = e => this.progressBarMouseUp(e.clientX)
        window.addEventListener('mouseup', this.progressBarMouseUpCallback )

        this.playPauseCallback = _ => this.playPauseAction()
        this.playPauseButton.addEventListener('click', this.playPauseCallback)
        this.nextSpeakerCallback = _ => this.goToSpeaker(this.currentSpeakerIndex + 1)
        this.contentView.querySelector('#js-nextButton').addEventListener('click', this.nextSpeakerCallback)
        // General
        this.onResizeCallback = _ => this.onResize()
        window.addEventListener('resize', this.onResizeCallback)
        this.goToSpeaker(0, false)
        this.updateContent()
    }

    progressBarMouseDown() {
        SPEECHES[this.currentSpeakerIndex].audio.pause()
        this.isDraggingProgressBar = true
    }

    progressBarMouseMove(_x) {
        if (!this.isDraggingProgressBar) { return }
        const progression = Math.max(0, Math.min(100, (_x - this.progressBar.rect.x) / this.progressBar.rect.width))
        this.seekBar.style.transform = `translateX(${-100 + progression * 100}%)`
        const destTimestamp = SPEECHES[this.currentSpeakerIndex].audio.duration() * progression
        this.goToSpeechContentPartAtTimestamp(destTimestamp)
    }

    progressBarMouseUp(_x) {
        if (!this.isDraggingProgressBar) { return }
        this.isDraggingProgressBar = false
        const progression = Math.max(0, Math.min(100, (_x - this.progressBar.rect.x) / this.progressBar.rect.width))
        const destTimestamp = SPEECHES[this.currentSpeakerIndex].audio.duration() * progression
        // this.pauseCurrentSpeech()
        this.resumeCurrentSpeech(destTimestamp)
        this.goToSpeechContentPartAtTimestamp(destTimestamp)
    }

    goToSpeechContentPartAtTimestamp(_destTimestamp) {
        for (const index in this.currentSpeechContent.parts) {
            const part = this.currentSpeechContent.parts[parseInt(index) + 1]
            if (!part) {
                this.goToSpeechContentPart(parseInt(index) + 1)
                break
            } if (part.end > _destTimestamp) {
                this.goToSpeechContentPart(parseInt(index))
                break
            }
        }
    }

    speechWheel(_deltaY) {
        // Mettre en pause l'audio
        this.pauseCurrentSpeech()
        // Enregistrer le delta ajouté et l'afficher
        this.speechDeltaY += _deltaY
        this.speechScrollingBox.style.transform = `translateY(${120 - this.currentSpeechContent.index * this.paragraphHeight - this.speechDeltaY}px)`
        // Lancer le timer de reprise de l'audio
        clearTimeout(this.speechScrollingTimer)
        this.speechScrollingTimer = setTimeout(_ => {
            // Détecter le paragraph séléctionné
            const offset = Math.round(this.speechDeltaY / this.paragraphHeight)
            const destinationIndex = Math.max(0, Math.min(this.currentSpeechContent.parts.length, this.currentSpeechContent.index + offset))
            // Changer l'audio
            const destBeforeIndex = destinationIndex - 1
            this.resumeCurrentSpeech(destinationIndex == 0 ? 0 : this.currentSpeechContent.parts[destBeforeIndex].end)
            // Sélectionner le paragraph
            this.goToSpeechContentPart(destinationIndex)
            this.speechDeltaY = 0
        }, 600)
    }

    updateContent() {
        if (!this.isAlive) { return }
        requestAnimationFrame(_ => this.updateContent())
        // Seekbar
        const currentSpeech = SPEECHES[this.currentSpeakerIndex]
        const seek = currentSpeech.audio.seek()
        if (!this.isDraggingProgressBar) {
            const translateX = Math.max(-100, Math.min(0, -100 + 100 * seek / currentSpeech.audio.duration()))
            this.seekBar.style.transform = `translateX(${translateX}%)`
        }
        // Speech content scrolling
        if (this.currentSpeechContent.parts[this.currentSpeechContent.index].end <= seek) {
            this.goToSpeechContentPart(this.currentSpeechContent.index + 1)
        }
    }

    goToSpeechContentPart(_destIndex) {
        this.currentSpeechContent.parts[this.currentSpeechContent.index].elem.classList.remove('current')
        if (_destIndex < this.currentSpeechContent.parts.length) {
            this.currentSpeechContent.index = _destIndex
            this.currentSpeechContent.parts[this.currentSpeechContent.index].elem.classList.add('current')
            this.speechScrollingBox.style.transform = `translateY(${120 - this.currentSpeechContent.index * this.paragraphHeight}px)`
        } else {
            this.speechScrollingBox.style.transform = `translateY(${120 - (this.currentSpeechContent.index + 1)* this.paragraphHeight}px)`
        }
    }

    playPauseAction() {
        if (this.playPauseButton.classList.contains('paused')) {
            this.resumeCurrentSpeech()
        } else {
            this.pauseCurrentSpeech()
        }
    }

    resumeCurrentSpeech(_timestamp = null) {
        if (this.isAudioPlaying) {
            SPEECHES[this.currentSpeakerIndex].audio.pause()
        }
        this.isAudioPlaying = true
        if (_timestamp != null) {
            SPEECHES[this.currentSpeakerIndex].audio.seek(_timestamp)
        }
        this.playPauseButton.classList.remove('paused')
        SPEECHES[this.currentSpeakerIndex].audio.play()
        SPEECHES[this.currentSpeakerIndex].audio.fade(0, 1, 600)
    }

    pauseCurrentSpeech() {
        if (!this.isAudioPlaying) { return }
        this.isAudioPlaying = false
        this.playPauseButton.classList.add('paused')
        SPEECHES[this.currentSpeakerIndex].audio.fade(1, 0, 600)
        setTimeout(_ => {
            if (!this.isAudioPlaying) {
                SPEECHES[this.currentSpeakerIndex].audio.pause()
            }
        }, 600)
    }

    goToSpeaker(i, _animated = true) {
        this.currentSpeakerIndex = i % 3
        const currentSpeech = SPEECHES[this.currentSpeakerIndex]
        // Speech content
        this.setContentFor(currentSpeech)
        // Audio playing
        this.setAudioFor(currentSpeech)
        // Speaker view
        this.setSpeaker(_animated)
    }

    setSpeaker(_animated) {
        if (_animated) {
            this.speakersBox.classList.add('animated')
        } else {
            this.speakersBox.classList.remove('animated')
        }
        this.initalSpeakers.forEach( (speaker, index) => {
            speaker.classList.remove('active', 'before', 'after')
            if (index <= this.currentSpeakerIndex) {
                speaker.classList.add('before')
            } else {
                speaker.classList.add('after')
            }
        })
        const speaker = this.initalSpeakers[this.currentSpeakerIndex]
        if (!speaker) { return }
        // A changer selon la taille d'écran
        if (window.innerWidth > 840) {
            const speakerCurrentCenter = speaker.offsetTop + (speaker.offsetHeight / 2) + 20
            const destinationCenter = this.windowHeight / 2
            this.speakersBox.style.transform = `translateY(${-1 * (speakerCurrentCenter - destinationCenter)}px)`
        } else {
            const speakerCurrentCenter = speaker.offsetLeft + (speaker.offsetWidth / 2)
            const destinationCenter = this.windowWidth / 2
            this.speakersBox.style.transform = `translateX(${-1 * (speakerCurrentCenter - destinationCenter)}px)`
        }

        speaker.classList.remove('before', 'after')
        speaker.classList.add('active')
    }

    setAudioFor(_speech) {
        SPEECHES.forEach(speech => {
            speech.audio.stop()
        })
        this.playPauseButton.classList.remove('paused')
        _speech.audio.volume(1)
        _speech.audio.play()
        this.isAudioPlaying = true
        _speech.audio.on('end', _ => {
            _speech.audio.off('end')
            this.goToSpeaker(this.currentSpeakerIndex + 1)
        })
    }

    setContentFor(_speech) {
        this.speechScrollingBox.innerHTML = null
        this.currentSpeechContent = { index: 0 }
        this.speechScrollingBox.style.transform = `translateY(120px)`
        this.currentSpeechContent.parts = _speech.content.map( (part, index) => {
            part.elem = document.createElement('div')
            part.elem.classList.add('paragraphBox')
            if (index == 0) {
                part.elem.classList.add('current')
            }
            const p = document.createElement('p')
            p.innerText = part.text
            part.elem.appendChild(p)
            this.speechScrollingBox.appendChild(part.elem)
            return part
        })
    }

    onResize() {
        const paragraphBox = this.contentView.querySelector('.paragraphBox')
        if (paragraphBox) {
            this.paragraphHeight = paragraphBox.offsetHeight
        }
        this.windowHeight = this.contentView.offsetHeight
        this.windowWidth = this.contentView.offsetWidth
        const progressBarRect = this.progressBar.getBoundingClientRect()
        this.progressBar.rect = {
            x: progressBarRect.x,
            width: progressBarRect.width
        }
        this.setSpeaker()
    }

    kill() {
        this.isAlive = false
        this.isAudioPlaying = false
        SPEECHES[this.currentSpeakerIndex].audio.stop()
        window.removeEventListener('resize', this.onResizeCallback)
        this.progressBar.removeEventListener('mousedown', this.progressBarMouseDownCallback )
        window.removeEventListener('mousemove', this.progressBarMouseMoveCallback)
        window.removeEventListener('mouseup', this.progressBarMouseUpCallback )
        this.wheelTouchScreenCompatibleManager.kill()
        this.speechScrollingBox.removeEventListener('wheelTouchScreenCompatible', this.speechWheelHandler)
        this.playPauseButton.removeEventListener('click', this.playPauseCallback)
        this.contentView.querySelector('#js-nextButton').removeEventListener('click', this.nextSpeakerCallback)
        SPEECHES.forEach(speech => speech.audio.off('end'))
    }

}