export default class BottlePage {

    constructor(_data) {
        if(_data) {
            const messageElement = document.querySelector('.message')
            const signatureContainerElement = document.querySelector('.signature')
            const signatureElement = document.querySelector('.signature p')

            messageElement.innerText = _data.content
            signatureElement.innerText = _data.signature

            // if(messageElement. > )

        }
        else {
            messageElement.innerText = 'Revenez plus tard, un message s\'affichera ici'
            signatureElement.innerText = ''
        }



    }

    kill() {
        
    }

}