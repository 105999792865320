import ISLANDS from './ISLANDS'


const isOnline = window.location.hostname == 'passation.synerghetic.net'

const CONFIG = {
    IS_ONLINE: isOnline,
    INTRO_ENABLED: isOnline ? true : false,
    MULTIBARGES_ENABLED: isOnline ? true : false,
    MAP_DEBUG: isOnline ? false : false, // Permet d'utiliser l'orbit control
    BUOYS_DEBUG: isOnline ? false : false,
    SOCKET_DEBUG: isOnline ? false : false,
    DEV_PAGE: isOnline ? null : null, // Ex: ISLANDS[1] => index à choisir selon l'île de travail
    SOCKET_SERVER: isOnline ? 'wss://api.passation.synerghetic.net' : 'ws://localhost:8001',
    FLAG_SERVER: isOnline ? 'https://api.passation.synerghetic.net' : 'http://localhost:8001',
    MAP_RADIUS: 120,
    ACCESSIBLE_MAP_RADIUS: 60,
    SECURITY_DISTANCE: 15,
    BOAT: {
        CORNERS_DEFAULT_ANGLE: [ Math.PI * 0.35, Math.PI * .65, Math.PI * 1.35, Math.PI * 1.65 ],
        CIRCLE_RADIUS: 1.15,
        START_X: -38,
        START_Z: 0
    }
}

export default CONFIG
