const EVENT_WHEEL_TOUCH_SCREEN_COMPATIBLE = 'wheelTouchScreenCompatible'

export default class WheelTouchScreenCompatibleManager {

    constructor(_elem) {
        this.elem = _elem
        this.wheelCallback = _e => this.wheelHandler(_e)
        this.elem.addEventListener('wheel', this.wheelCallback)
        this.touchStartCallback = _e => this.touchStartHandler(_e)
        this.elem.addEventListener('touchstart', this.touchStartCallback)
        this.touchMoveCallback = _e => this.touchMoveHandler(_e, false)
        this.elem.addEventListener('touchmove', this.touchMoveCallback)
        this.touchEndCallback = _e => this.touchMoveHandler(_e, true)
        this.elem.addEventListener('touchend', this.touchEndCallback)
    }

    touchStartHandler(_e) {
        this.lastPageY = _e.pageY
        this.lastPageX = _e.pageX
    }

    touchMoveHandler(_e, _isEnding) {
        if (!this.lastPageY || !this.lastPageX) { return }
        const deltaY = (this.lastPageY - _e.pageY)
        const deltaX = (this.lastPageX - _e.pageX)
        this.lastPageY = _e.pageY
        this.lastPageX = _e.pageX
        this.dispatchEvent(_e, deltaY, deltaX)
        if (_isEnding) {
            this.lastPageY = null
            this.lastPageX = null
        }
    }

    wheelHandler(_e) {
        this.dispatchEvent(_e, _e.deltaY, _e.deltaX)
    }

    dispatchEvent(_e, _deltaY, _deltaX) {
        const event = new CustomEvent(EVENT_WHEEL_TOUCH_SCREEN_COMPATIBLE, {
            detail: {
                sourceEvent: _e,
                deltaY: _deltaY,
                deltaX: _deltaX
            }
        })
        this.elem.dispatchEvent(event)
    }

    kill() {
        this.elem.removeEventListener('wheel', this.wheelCallback)
        this.elem.removeEventListener('touchstart', this.touchStartCallback)
        this.elem.removeEventListener('touchmove', this.touchMoveCallback)
        this.elem.removeEventListener('touchend', this.touchEndCallback)
    }

}