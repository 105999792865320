import * as THREE from 'three'
import ACTIONS from '../constants/ACTIONS'
import CONFIG from '../constants/CONFIG'
import EVENTS from '../constants/EVENTS'
import ISLANDS from '../constants/ISLANDS'
import MathHelper from '../managers/MathHelper'
import SingleBarge from './SingleBarge'
import Anchor from './../../models/anchor.glb'

export default class UserBarge extends SingleBarge {

    constructor(_scene, _gltfLoader, _multibarges) {
        super(_scene, _gltfLoader, {}, true)
        this.buoysPositions = []
        this.scene = _scene
        this.collision = {
            ids: [],
            states: {},
            history: [],
            speed: {
                x: 0,
                z: 0
            }
        }
        this.multibarges = _multibarges
        this.destination = null
        this.speed = 0
        this.rotation = 0
        this.lastBorderDistance = CONFIG.ACCESSIBLE_MAP_RADIUS
        this.boatCorners = null
        this.pointer = new THREE.Mesh(new THREE.BoxGeometry(0.4, 0.4, 0.4), new THREE.MeshNormalMaterial())
        _gltfLoader.load(Anchor, gltf => {
            this.pointer = gltf.scene
        })
    }

    loadBoat(_scene, _gltfLoader, _boatConfig) {
        super.loadBoat(_scene, _gltfLoader, _boatConfig, _ => {
            const data = { x: this.element.position.x, z: this.element.position.z, rotY: this.rotation, collisions: []}
            this.multibarges.updateBarge(data)
        })
    }

    getCollisionSpeedOnBargeAxis() {
        const collisionSpeed = MathHelper.distanceBetweenCoordinates(0, 0, this.collision.speed.x, this.collision.speed.z)
        const collisionAngle = MathHelper.angleBetweenCoordinates(0, 0, this.collision.speed.x, this.collision.speed.z)
        const bargeAxisSpeed = Math.sin(collisionAngle + Math.PI/2) * collisionSpeed
        return bargeAxisSpeed
    }

    updateClickNavigation(_refreshRateFactor) {
        // Gestion du click to move
        this.speed = 0
        const progress = this.destination.progression / this.destination.frames
        this.element.position.x = this.destination.from.x + (this.destination.x - this.destination.from.x) * progress
        this.element.position.z = this.destination.from.z + (this.destination.z - this.destination.from.z) * progress
        if (this.rotation != this.destination.rotation) {
            const rotationDelta = Math.abs(this.destination.from.rotation - this.destination.rotation)
            const frames = Math.min(Math.round(rotationDelta * 80), this.destination.frames)
            const rotProgression = Math.min(1, (this.destination.progression / frames))
            this.rotation = this.destination.from.rotation + (this.destination.rotation - this.destination.from.rotation) * rotProgression
            this.element.rotation.y = this.rotation
        }
        if (progress >= 1) {
            this.destination = null
            if (this.element.rotation.y > Math.PI) {
                this.element.rotation.y = ((this.element.rotation.y + Math.PI) % (Math.PI * 2)) - Math.PI
            }
            if (this.element.rotation.y < -Math.PI) {
                this.element.rotation.y = ((this.element.rotation.y - Math.PI) % (Math.PI * 2)) + Math.PI
            }
            this.rotation = this.element.rotation.y
        } else {
            this.destination.progression += this.destination.progressSpeed * _refreshRateFactor
            this.destination.progressSpeed = Math.min(1, this.destination.progressSpeed * 1.01 * Math.max(0.8, _refreshRateFactor))
        }
    }

    updateDefaultNavigation(_refreshRateFactor) {
        // Gestion des bordures de la map
        const borderDistance = Math.min(CONFIG.ACCESSIBLE_MAP_RADIUS - Math.abs(this.element.position.x), CONFIG.ACCESSIBLE_MAP_RADIUS - Math.abs(this.element.position.z))
        if (borderDistance <= CONFIG.SECURITY_DISTANCE && this.lastBorderDistance > borderDistance) {
            this.speed = Math.max(Math.min(this.speed, borderDistance / CONFIG.SECURITY_DISTANCE), borderDistance / (-2 * CONFIG.SECURITY_DISTANCE))
        }
        this.lastBorderDistance = borderDistance
        // Gestion du déplacement 
        this.speed = Math.max(Math.min(this.speed, 1), -0.5) // Range speed between 1 and - 0.5
        const normalizedRot = this.rotation - Math.PI/2
        // Update element position and rotation
        this.element.position.x -= Math.cos(normalizedRot) * -(this.speed + this.collision.speed.x) / 10 * _refreshRateFactor
        this.element.position.z += Math.sin(normalizedRot) * -(this.speed + this.collision.speed.z) / 10 * _refreshRateFactor
        this.element.rotation.y = this.rotation
        // Reduce speeds
        this.speed =  Math.abs(this.speed) < 0.002 ? 0 : this.speed * 0.99
        this.collision.speed.x = Math.abs(this.collision.speed.x) < 0.002 ? 0 : this.collision.speed.x * 0.99
        this.collision.speed.z = Math.abs(this.collision.speed.z) < 0.002 ? 0 : this.collision.speed.z * 0.99
    }

    declareCollision(_receiverId, _theta, _collisionSpeed = null, _collisionDefaultState = 0) {
        const now = Date.now()
        this.collision.history = this.collision.history.filter( h => now - h.timestamp < 3000)
        if (this.collision.history.filter( h => h.receiverId == _receiverId).length > 6) {
            this.element.position.x += 0.2
            return
        }
        const totalSpeed = this.speed + this.getCollisionSpeedOnBargeAxis()
        const speed = this.destination ?  0.4 : (totalSpeed >= 0 ? Math.max(0.2, totalSpeed) : Math.min(-0.2, totalSpeed))
        this.destination = null
        this.speed = 0
        const normalizedRot = this.rotation + Math.PI / 2
        const speedX = _collisionSpeed ? _collisionSpeed.x : Math.abs(Math.cos(normalizedRot)) * -speed
        const speedZ = _collisionSpeed ? _collisionSpeed.z : Math.abs(Math.sin(normalizedRot)) * -speed
        this.collision.speed.x = speedX
        this.collision.speed.z = speedZ
        this.collision.ids.push(_receiverId)
        this.collision.states[_receiverId] = _collisionDefaultState
        this.collision.history.push({receiverId: _receiverId, timestamp: now})
        const speedFactor = (_theta && _theta > Math.PI * 0.5 && _theta < Math.PI * 1.5) ? 1 : -1
        return {
            sender: this.id,
            receiver: _receiverId,
            speed: {
                x: speedX * speedFactor,
                z: speedZ * speedFactor
            }
        }
    }

    isCollisionAvailable(_id) {
        if (this.collision.ids.includes(_id)) {
            this.collision.states[_id]++
            if (this.collision.states[_id] > 30) {
                const index = this.collision.ids.indexOf(_id);
                if (index !== -1) { this.collision.ids.splice(index, 1) }
                delete this.collision.states[_id]
            }
            return false
        }
        return true
    }

    updateBoatCollisions(_otherBarges) {
        if (!this.lastData) { return }
        if (this.speed == 0 && this.destination == null && this.collision.speed.x == 0 && this.collision.speed.z == 0) { return }
        let collisions = []
        const _ourBarge = this.lastData
        _otherBarges.forEach(_barge => {
            if (!this.isCollisionAvailable(_barge.id)) { return }
            if (_barge.x == CONFIG.BOAT.START_X && _barge.z == CONFIG.BOAT.START_Z) { return }
            const dist = MathHelper.distanceBetweenCoordinates(_barge.x, _barge.z, _ourBarge.x, _ourBarge.z)
            if (dist < 2.5 && this.boatCorners) {
                if (dist < 0.5) {
                    this.element.position.x += 3
                } else {
                    let isInCollision = false
                    const bargeCorners = this.computeBoatCorners(_barge)
                    for (const corner of this.boatCorners) {
                        if (MathHelper.isPointInPolygon(corner, bargeCorners)) {
                            isInCollision = true
                            break
                        }
                    }
                    if (isInCollision) {
                        const theta = ((_ourBarge.rotY % (2*Math.PI)) - (_barge.rotY % (2*Math.PI)) + (2*Math.PI)) % (2*Math.PI)
                        collisions.push(this.declareCollision(_barge.id, theta))
                    }
                }
            }
        })
        return collisions.length == 0 ? null : collisions
    }

    updateBuildingCollisions() {
        if (!this.boatCorners) { return }
        if (!this.currentIsland) { return }
        if (this.currentIsland.id == 'bottle'){ return }
        if (!this.isCollisionAvailable(this.currentIsland.id)) { return }
        let isInCollision = false
        for (const corner of this.boatCorners) {
            if (this.currentIsland.solid.type == 'rectangle') {
                if (MathHelper.isPointInRectangle(corner, this.currentIsland.solid)) {
                    isInCollision = true
                    break
                }
            } else {
                if (MathHelper.isPointInPolygon(corner, this.currentIsland.solid.corners)) {
                    isInCollision = true
                    break
                }
            }
        }
        // Si le besoin s'en fait sentir => Rajouter une vérif des points de l'island par rapport au bateau
        if (isInCollision) {
            const buildingRotation = this.currentIsland.solid.rotation ? this.currentIsland.solid.rotation : 0
            const theta = ((this.lastData.rotY % (2*Math.PI)) - buildingRotation + (2*Math.PI)) % (2*Math.PI)
            this.declareCollision(this.currentIsland.id, theta, null, 20)
        }
    }

    computeBoatCorners(_boatData) {
        if (!_boatData) { return }
        const boatCorners = CONFIG.BOAT.CORNERS_DEFAULT_ANGLE.map(cornerDefaultAngle => {
            const x = _boatData.x + (CONFIG.BOAT.CIRCLE_RADIUS * Math.cos(cornerDefaultAngle - _boatData.rotY))
            const z = _boatData.z + (CONFIG.BOAT.CIRCLE_RADIUS * Math.sin(cornerDefaultAngle - _boatData.rotY))
            return { x, z }
        })
        if (CONFIG.MAP_DEBUG) {
            if (!this.cornersPreview) {
                this.cornersPreview = boatCorners.map(corner => {
                    const solidMesh = new THREE.Mesh(new THREE.BoxGeometry(0.1, 10, 0.1), new THREE.MeshNormalMaterial())
                    solidMesh.position.set(corner.x, 0, corner.z)
                    this.scene.add(solidMesh)
                    return solidMesh
                })
            } else {
                this.cornersPreview.forEach((corner, index) => {
                    corner.position.set(boatCorners[index].x, 0, boatCorners[index].z)
                })
            }
        }
        return boatCorners
    }

    update(_otherBarges, _refreshRateFactor = 1) {
        if (!this.element) { return }
        const newCollisions = this.updateBoatCollisions(_otherBarges)
        this.updateBuyosCollisions()
        this.updateBuildingCollisions()
        // Navigation classique
        this.destination ? this.updateClickNavigation(_refreshRateFactor) : this.updateDefaultNavigation(_refreshRateFactor)
        if (this.pointer.parent) {
            if (this.destination) {
                this.pointer.position.y = 0.7 - 1.7 * (this.destination.progression / this.destination.frames)
                this.pointer.rotation.y += 0.02
            } else {
                this.scene.remove(this.pointer)
            }
        }
        // Update multibarges if needed
        const data = { x: this.element.position.x, z: this.element.position.z, rotY: this.rotation, collisions: newCollisions}
        if (this.lastData) {
            const movement = Math.abs(this.lastData.x - data.x) + Math.abs(this.lastData.z - data.z)
            const rotation = Math.abs(this.lastData.rotY - data.rotY)
            if (movement > 0.02 || rotation > 0.02) {
                this.multibarges.updateBarge(data)
                this.boatCorners = this.computeBoatCorners(data)
                this.lastData = data
            }
        } else {
            this.lastData = data
        }
    }

    move(power, orientation = 0) {
        const dir = (orientation != 0 && this.speed < 0) ? -1 : 1
        this.speed += power * dir
        this.rotation -= orientation / 120 * dir
    }

    break() {
        this.speed = Math.max(0, this.speed - 0.007)
    }

    handleClick(vector) {
        const max = CONFIG.ACCESSIBLE_MAP_RADIUS
        const destination = {
            x: Math.min(max, Math.max(-max, vector.x)),
            z: Math.min(max, Math.max(-max, vector.z))
        }
        const currentTheta = this.element.rotation.y
        let theta = MathHelper.angleBetweenCoordinates(this.element.position.x, this.element.position.z, destination.x, destination.z)
        let bestTheta = null
        let destinationTheta = null
        let i = Math.floor(currentTheta / (Math.PI*2)) - 1
        let shortestDist = 1000
        while (destinationTheta == null) {
            const triedTheta = theta + (i * 2 * Math.PI)
            const dist = Math.abs(currentTheta - triedTheta)
            if (dist < shortestDist) {
                shortestDist = dist
                bestTheta = triedTheta
            } else if (dist > shortestDist) {
                destinationTheta = bestTheta
            }
            i++
        }
        const deltaX = this.element.position.x - destination.x
        const deltaZ = this.element.position.z - destination.z
        const distance = Math.sqrt( deltaX*deltaX + deltaZ*deltaZ )
        if (!this.pointer.parent) {
            this.scene.add(this.pointer)
        }
        this.pointer.position.set(destination.x, 0, destination.z)
        console.log("------", this.destination, "------")
        this.destination = {
            from: {
                x: this.element.position.x,
                z: this.element.position.z,
                rotation: this.element.rotation.y
            },
            x: destination.x,
            z: destination.z,
            rotation: destinationTheta,
            progression: 0,
            progressSpeed: (this.destination && this.destination.progressSpeed) ? this.destination.progressSpeed : 0.5,
            frames: distance * 20
        }
    }

    handleKeyboardAction(_action, _isBoostKeyDown) {
        if (this.destination !== null) { return }
        const multiplier = _isBoostKeyDown ? 2 : 1
        switch (_action) {
            case ACTIONS.UP:
                this.move(0.007 * multiplier)
                break
            case ACTIONS.RIGHT:
                this.move(0.002 * multiplier, 1)
                break
            case ACTIONS.DOWN:
                this.move(-0.0035 * multiplier)
                break
            case ACTIONS.LEFT:
                this.move(0.002 * multiplier, -1)
                break
            case ACTIONS.BREAK:
                this.break()
                break
            default: break
        }
    }

    handleMultibargesEvent(_event, _data, _socketId) {
        if (_event != EVENTS.ON.BARGE_UPDATE) { return }
        if (!_data.collisions) { return }
        for (const collision of _data.collisions) {
            if (collision.receiver == _socketId) {
                this.declareCollision(collision.sender, null, collision.speed)
                break
            }
        }
    }

    updateBoatMaterial(_name, _data) {
        super.updateBoatMaterial(_name, _data)
        this.multibarges.updateBargeStyle(_name, _data)
    }

    currentIslandDidChange(_island) {
        this.currentIsland = _island
    }

    configureBuoysCollisions(_buyos) {
        this.buoysPositions = _buyos
        if (CONFIG.BUOYS_DEBUG) {
            this.buoysDebug = {}
            this.buoysPositions.forEach(_buoysRow => {
                _buoysRow.forEach(_buoysColumn => {
                    _buoysColumn.forEach(_buoy => {
                        const solidMesh = new THREE.Mesh(new THREE.CylinderGeometry(_buoy.radius, _buoy.radius, 6, 6, 2), new THREE.MeshNormalMaterial())
                        solidMesh.position.set(_buoy.x, 0, _buoy.z)
                        this.scene.add(solidMesh)
                        this.buoysDebug[_buoy.id] = solidMesh
                    })
                })
            })
        }
    }

    updateBuyosCollisions() {
        if (!this.lastData) { return }
        if (this.speed == 0 && this.destination == null && this.collision.speed.x == 0 && this.collision.speed.z == 0) { return }
        const _ourBarge = this.lastData
        const dangerousBuoys = this.getBuyosInCurrentArea()
        dangerousBuoys.forEach(_buoy => {
            if (CONFIG.BUOYS_DEBUG) {
                this.buoysDebug[_buoy.id].position.y = 2
            }
            if (!this.isCollisionAvailable(_buoy.id)) { return }
            const dist = MathHelper.distanceBetweenCoordinates(_buoy.x, _buoy.z, _ourBarge.x, _ourBarge.z)
            if (dist < 2.5) {
                // console.log('--------- near : ', _buoy.x, _buoy.z, dist)
                let isInCollision = false
                const ourBargeCorners = this.computeBoatCorners(_ourBarge)
                for (const corner of ourBargeCorners) {
                    const dist = MathHelper.distanceBetweenCoordinates(_buoy.x, _buoy.z, corner.x, corner.z)
                    if (dist < _buoy.radius) {
                        isInCollision = true
                        break
                    }
                }
                if (isInCollision) {
                    const theta = _ourBarge.rotY % (2*Math.PI)
                    this.declareCollision(_buoy.id, theta)
                }
            }
        })
    }

    getBuyosInCurrentArea() {
        if (!this.lastData) { return [] }
        const indexes = MathHelper.getXZIndexes(this.lastData)
        if (!this.buoysPositions[indexes.x]) { return [] }
        if (!this.buoysPositions[indexes.x][indexes.z]) { return [] }
        // console.log(indexes.x, indexes.z, this.buoysPositions[indexes.x][indexes.z])
        return this.buoysPositions[indexes.x][indexes.z]
    }

    stop() {
        this.destination = null
        this.speed = 0
    }

}