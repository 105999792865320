const MathHelper = {
    distanceBetweenCoordinates: (_x1, _y1, _x2, _y2) => Math.sqrt((_x1 - _x2) ** 2 + (_y1 - _y2) ** 2),
    angleBetweenCoordinates: (_x1, _y1, _x2, _y2) =>  Math.PI/-2 + -1 * Math.atan2((_y1 - _y2), (_x1 - _x2)), // range [-PI, PI]
    isPointInRectangle: (_point, _rectangle) => !(_rectangle.minX >= _point.x || _rectangle.maxX <= _point.x || _rectangle.minZ >= _point.z || _rectangle.maxZ <= _point.z),
    isPointInPolygon: (_point, _polygon) => {
        const x = _point.x
        const z = _point.z
        let inside = false
        for (var i = 0, j = _polygon.length - 1; i < _polygon.length; j = i++) {
            const xi = _polygon[i].x
            const zi = _polygon[i].z
            const xj = _polygon[j].x
            const zj = _polygon[j].z
            const intersect = ((zi > z) != (zj > z)) && (x < (xj - xi) * (z - zi) / (zj - zi) + xi)
            if (intersect) inside = !inside
        }
        return inside
    },
    getXZIndexes: (_data) => ({
        x: Math.floor(_data.x / 20) + 8,
        z: Math.floor(_data.z / 20) + 8
    }),
    easeOutElastic: (t, p = 0.7) => ( Math.pow(2,-10*t) * Math.sin((t-p/4)*(2*Math.PI)/p) + 1 )
}
export default MathHelper