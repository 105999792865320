const ACTIONS = {
    UP: 'up',
    DOWN: 'down',
    RIGHT: 'right',
    LEFT: 'left',
    BREAK: 'break',
    OPEN: 'open',
    EXIT: 'exit',
}

export default ACTIONS